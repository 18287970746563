import React from "react";
import { Dropdown } from "react-bootstrap";
import { BiCookie } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/features/modals/modalsSlice";
import axios from "axios";
import { getURL } from "../../../redux/selectors";

const Cookie = () => {
  const dispatch = useDispatch();
  const url = useSelector((state) => getURL(state));
  const handleClear = async () => {
    dispatch(setLoading(true));
    await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url: `${url}?refresh`,
      data: {
        service: "refresh",
      },
    });
    window.location.reload(true);
  };
  return (
    <Dropdown.Item href="#" onClick={handleClear}>
      <div className="d-flex justify-content-start align-items-center gap-1">
        <BiCookie />
        Clear Cache
      </div>
    </Dropdown.Item>
  );
};

export default Cookie;
