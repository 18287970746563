import React from "react";
import { getFormDesign, getScreenState, getTabIdx } from "../../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import S1MultiSelect from "../s1 inputs/S1MultiSelect";
import S1StringsMulti from "../s1 inputs/S1StringsMulti";
import S1Selector from "../s1 inputs/S1Selector";
import S1TextField from "../s1 inputs/S1TextField";
import S1CheckBox from "../s1 inputs/S1CheckBox";
import S1TextArea from "../s1 inputs/S1TextArea";
import S1MemoryTable from "../s1 inputs/S1MemoryTable";
import S1CalendarDate from "../s1 inputs/S1CalendarDate";
import S1NumberField from "../s1 inputs/S1NumberField";
import S1DateTime from "../s1 inputs/S1DateTime";
import S1Strings from "../s1 inputs/S1Strings";
import S1Time from "../s1 inputs/S1Time";
import serviceGetFormDesign from "../../../services/getFormDesign";
import { setFormDesign, setLoading } from "../../../redux/features/ui/uiSlice";
import S1DetailGrid from "../detail grid/S1DetailGrid";
import ErrorDisplay from "../ErrorDisplay";
import S1IntField from "../s1 inputs/S1IntField";
import S1Image from "../s1 inputs/S1Image";
import S1SubForm from "../s1 inputs/S1SubForm";
import S1RelDocs from "../rel docs/S1RelDocs";
import S1WebPage from "../s1 inputs/S1WebPage";
import FormCaption from "./form/FormCaption";

const Form = (props) => {
  var panels = {};
  const [activePanel, setActivePanel] = React.useState({});
  const dispatch = useDispatch();
  const design = useSelector((state) => getFormDesign(state, props.tabID));
  const formDesign = React.useMemo(() => design, [design]);
  const screenState = useSelector((state) => getScreenState(state, props.tabID));
  const idx = useSelector((state) => getTabIdx(state, props.tabID));

  const initForm = async () => {
    dispatch(setLoading({ show: true, tabID: props.tabID }));
    const form = await serviceGetFormDesign(props.tabID);
    dispatch(setFormDesign({ value: form, idx, tabID: props.tabID }));
    dispatch(setLoading({ show: false, tabID: props.tabID }));
  };

  React.useEffect(() => {
    if (screenState == "form") {
      if (!formDesign) {
        initForm();
      }
    }
    activePanel != panels && setActivePanel(panels);
  }, [screenState]);

  const Recursive = (data, panel) => {
    if (panel.isPanelHeader) {
      panels[panel.index] = 0;
      return (
        <div
          className={`form-tab text-truncate px-1 ${activePanel[panel.index] === panel.i ? "active" : ""}`}
          key={`${data.sid}_${panel.i}`}
          onClick={() => setActivePanel({ ...activePanel, [panel.index]: panel.i })}
          style={{
            userSelect: "none",
            cursor: "pointer",
            display: "flex",
            borderBottom: activePanel[panel.index] == panel.i ? "2px solid rgb(64,180,252)" : "none",
            marginBottom: activePanel[panel.index] == panel.i ? "-2px" : "0",
            fontSize: activePanel[panel.index] == panel.i ? "1.3rem" : "1.2rem",
            fontWeight: activePanel[panel.index] == panel.i ? 500 : 400,
            alignItems: "flex-end",
          }}
        >
          {data.title}
        </div>
      );
    }
    if (panel.isPanelChild) {
      return (
        <div
          className={`mt-2 ${activePanel[panel.index] === panel.i ? "active show" : ""}`}
          role="tabpanel"
          style={{ display: activePanel[panel.index] === panel.i ? "block" : "none" }}
          key={`${data.sid}_${panel.i}`}
        >
          {Recursive(data.items, { isPanelHeader: false, isPanelChild: false })}
        </div>
      );
    }
    return data.map((element) => {
      switch (element.xtype) {
        case "container":
          return (
            <React.Fragment key={element.index}>
              {Recursive(element.items, {
                isPanelHeader: false,
                isPanelChild: false,
              })}
            </React.Fragment>
          );
        case "s1cont":
          return (
            <div key={element.index} className="s1cont" style={{ flex: `${element.flex * 100}%` }}>
              {element.title != "" && element.title ? (
                <div className="d-flex flex-column w-100">
                  <legend style={{ fontSize: "1.2rem", borderBottom: "2px solid black" }}>{element.title}</legend>
                  <div className="d-flex gap-2">
                    {Recursive(element.items, {
                      isPanelHeader: false,
                      isPanelChild: false,
                    })}
                  </div>
                </div>
              ) : (
                Recursive(element.items, {
                  isPanelHeader: false,
                  isPanelChild: false,
                })
              )}
            </div>
          );
        case "s1panel":
          return (
            <div className="s1panel" style={{ flex: `${element.flex * 100}%` }} key={element.index}>
              {element.title != "" && element.title && (
                <>
                  <legend className="text-truncate" style={{ fontSize: "1.2rem", borderBottom: "2px solid black" }}>
                    {element.title}
                  </legend>
                </>
              )}
              {Recursive(element.items, {
                isPanelHeader: false,
                isPanelChild: false,
              })}
            </div>
          );
        case "s1tabpanel":
          return (
            <div className="s1tabpanel" key={element.index} style={{ flex: `${element.flex * 100}%` }}>
              <nav className="overflow-auto d-flex">
                <div
                  className="nav nav-tabs"
                  role="tablist"
                  style={{ inlineSize: "max-content", flex: 1, borderBottom: "2px solid black", flexWrap: "nowrap" }}
                >
                  {element.items.map((item, i) =>
                    Recursive(item, {
                      isPanelHeader: true,
                      isPanelChild: false,
                      i,
                      index: element.index,
                    })
                  )}
                </div>
              </nav>
              <div className="tab-content">
                {element.items.map((item, i) =>
                  Recursive(item, {
                    isPanelHeader: false,
                    isPanelChild: true,
                    i,
                    index: element.index,
                  })
                )}
              </div>
            </div>
          );
        case "s1multiselect":
          return <S1MultiSelect element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1stringsmulti":
          return <S1StringsMulti element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1selector":
          return <S1Selector element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1textfield":
          return <S1TextField element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1subform":
          return <S1SubForm element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1checkbox":
          return <S1CheckBox element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1spacer":
          return (
            <div
              className="s1spacer"
              key={element.index}
              style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
            />
          );
        case "s1textarea":
          return <S1TextArea element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1memorytable":
          return <S1MemoryTable element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1numberfield":
          return <S1NumberField element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1intfield":
          return <S1IntField element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1calendardate":
          return <S1CalendarDate element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1datetime":
          return <S1DateTime element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1strings":
          return <S1Strings element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1image":
          return <S1Image element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1time":
          return <S1Time element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1webpage":
          return <S1WebPage element={element} tabID={props.tabID} screenState="form" key={element.index} />;
        case "s1detailgrid":
          return <S1DetailGrid element={element} tabID={props.tabID} key={element.index} />;
        case "s1reldocs":
          return <S1RelDocs element={element} tabID={props.tabID} key={element.index} />;
        default:
          return (
            <div
              className="bg-danger"
              key={element.index}
              style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
            >
              {element.xtype}
            </div>
          );
      }
    });
  };
  return (
    <div style={{ ...props.style }} className="s1-object-form">
      <FormCaption tabID={props.tabID} />
      {formDesign &&
        (formDesign.success ? (
          Recursive(formDesign.form, {
            isPanelHeader: false,
            isPanelChild: false,
          })
        ) : (
          <ErrorDisplay error={formDesign?.error} />
        ))}
    </div>
  );
};

export default Form;
