import React from "react";
import { useDispatch, useSelector } from "react-redux/es";
import { set } from "../../../redux/features/settings/settingsSlice";

const CalendarColour = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);

  const [value, setValue] = React.useState(settings.calendarColor ?? "#0d6efd");

  const handleChange = (e) => {
    setValue(e.target.value);
    dispatch(set({ key: "calendarColor", value: e.target.value }));
  };

  return (
    <div className="custom w-100" style={{ flex: `1 1 100%`, overflow: "hidden" }}>
      <div className="inputGroup">
        <label className="text-truncate">Χρώμα:</label>
        <div className={`input-with-buttons`} style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }} role="button">
          <div className="d-flex w-100">
            <input
              role="button"
              type="color"
              className={`w-100`}
              readOnly={true}
              value={value}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarColour;
