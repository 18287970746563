import React from "react";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux/es";
import {
  setBrowserInfo,
  setLoading,
  setSortField,
  showTHeadContextMenu,
} from "../../../../../redux/features/ui/uiSlice";
import { getSortField } from "../../../../../redux/selectors";
import getBrowserInfo from "../../../../../services/getBrowserInfo";

const Sort = ({ sort }) => {
  const dispatch = useDispatch();
  const thead = useSelector((state) => state.ui.contextMenu.thead);
  const sortField = useSelector((state) => getSortField(state, thead.tabID));

  const handleSort = async (direction) => {
    dispatch(showTHeadContextMenu({ show: false }));
    if (`${direction}${thead.column}` != sortField) {
      dispatch(setSortField({ tabID: thead.tabID, value: `${direction}${thead.column}` }));
      dispatch(setLoading({ show: true, tabID: thead.tabID }));
      const browserInfo = await getBrowserInfo(thead.tabID);
      dispatch(setBrowserInfo({ value: browserInfo, tabID: thead.tabID }));
      dispatch(setLoading({ show: false, tabID: thead.tabID }));
    }
  };

  return (
    <div className="tab-list-context-menu-action" role="button" onClick={() => handleSort(sort == "up" ? "" : "*")}>
      <div className="d-flex align-items-center">
        {sort == "up" ? <BiSortUp className="me-1" /> : <BiSortDown className="me-1" />}
        <span>{sort == "up" ? "Αύξουσα ταξινόμηση" : "Φθίνουσα ταξινόμηση"}</span>
      </div>
    </div>
  );
};

export default Sort;
