import React from "react";
import { FaChevronCircleLeft } from "react-icons/fa";

const ScrollLeft = () => {
  const handleScroll = () => {
    const tabWrapper = document.getElementById("tab-list-wrapper");
    tabWrapper.scrollBy({
      left: -100,
      behavior: "smooth",
    });
  };
  return (
    <button className="tab-list-control-left" onClick={handleScroll}>
      <FaChevronCircleLeft />
    </button>
  );
};

export default ScrollLeft;
