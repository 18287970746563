import React from "react";
import { Dropdown } from "react-bootstrap";
import { AiOutlineLock } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setPasswordModal } from "../../../redux/features/modals/modalsSlice";

const Password = () => {
  const dispatch = useDispatch();
  return (
    <Dropdown.Item href="#" onClick={() => dispatch(setPasswordModal(true))}>
      <div className="d-flex justify-content-start align-items-center gap-1">
        <AiOutlineLock />
        Αλλαγή κωδικού
      </div>
    </Dropdown.Item>
  );
};

export default Password;
