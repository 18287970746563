import React from "react";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setLoading,
  setNewTab,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import PickerSpinner from "../PickerSpinner";
import getSelectorData from "../../../services/getSelectorData";
import { calculate } from "../../../services/calculate";
import cleanEditor from "../../functions/cleanEditor";
import { getRootsByKey } from "../../../redux/selectors/roots";
import { setScreenFromJob } from "../../../redux/features/screens/screensSlice";
import { getScreens } from "../../../redux/selectors/screens";

const S1Selector = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const root = useSelector((state) => getRootsByKey(state, element.redirector));
  const currentGridLine = grid?.currentLine;
  const filters = useSelector((state) => getFilters(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const popUpRedirection = useSelector((state) => state.settings.app?.popUpRedirection);

  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const [pickerLoading, setPickerLoading] = React.useState(false);
  const [selectorData, setSelectorData] = React.useState({});
  const [error, setError] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState("");

  const [returnIndex, setReturnIndex] = React.useState(-1);
  const [keyIndex, setKeyIndex] = React.useState(-1);

  const [editor, setEditor] = React.useState(element.editor);

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const keyNameFromLine = key.split("_")[0];
  //Εάν έχει _ στο όνομα πχ ITELINES.MTRL_ITEM_NAME
  const keyname = editor.split("|")[2];
  const tablename = editor.split("|")[1];
  const underScore1 = key.split("_")[0];
  const underScore2 = tablename;
  const underScore3 = key.split("_")[key.split("_").length - 1];

  const model =
    screenState == "form"
      ? formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0]
      : { editor: element.editor };

  const boundRef = React.useRef(null);
  const pickerRef = React.useRef(null);
  const searchRef = React.useRef(null);
  const tableRef = React.useRef(null);

  const [searchText, setSearchText] = React.useState("");
  const [timeoutId, setTimeoutId] = React.useState(null);

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  const handleCalculate = async (table, key, value) => {
    if (grid) {
      if (value == "") {
        currentGridLine.line &&
          Object.keys(currentGridLine.line).forEach((x) => {
            if (x.indexOf("_") > -1) {
              if (x.split("_")[0] == underScore1 && x.split("_")[1] == underScore2)
                dispatch(setGridChange({ tabID, name: grid.model, key: x, value: "" }));
            }
          });
      }
      dispatch(setGridChange({ tabID, name: grid.model, key: keyname, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  const handleRedirect = () => {
    const commandParams = {
      list: root?.list,
      form: root?.form,
      rj: root?.rj,
      AppId: process.env.REACT_APP_SOFTONE_APPID,
      autoLocate: true,
    };
    const value = {
      cmd: "EditMaster",
      text: root?.text,
      idx: `redirector_${element.redirector}`,
      autoLocateID: grid ? currentGridLine?.line?.[keyNameFromLine] : data?.[table]?.[0]?.[key],
      command: root?.command ?? element.redirector,
      commandParams,
      screenState: {},
      isWindow: popUpRedirection == 1,
      objectState: "view",
    };
    dispatch(
      setScreenFromJob({
        key: `redirector_${element.redirector}`,
        value,
      })
    );
    dispatch(setNewTab(value));
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(filters?.data?.[table]?.[key] ?? "");
      setSelectedRow(filters?.data?.[table]?.[key] ?? "");
    }
  }, [filters?.data?.[table]?.[key]]);

  React.useEffect(() => {
    element.name == "CRCUSNAIRE.CRMQSTNNAIRE" && console.log(element);
    if (screenState == "form" && !grid) {
      if (data?.[table]?.[0]?.[key]) {
        var value = "";
        if (element.selectorVF !== "") {
          const selectorArray = element.selectorVF.split("|");
          const splitted = selectorArray[selectorArray.length - 1].split(".");
          const tableName = splitted[0];
          const fieldName = splitted[1];
          value = data?.[tableName]?.[0]?.[fieldName] ?? "";
        } else {
          value = data?.[table]?.[0]?.[key] ?? "";
        }
        setValue(value);
      } else {
        setValue("");
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if ((currentGridLine?.line?.[keyNameFromLine] || currentGridLine?.line?.[keyNameFromLine] == "") && grid) {
      var value = "";
      if (element.selectorVF !== "") {
        const selectorArray = element.selectorVF.split("|");
        const splitted = selectorArray[selectorArray.length - 1].split(".");
        const fieldName = splitted[1];
        value = currentGridLine?.line?.[fieldName] ?? "";
      } else {
        value = currentGridLine?.line?.[key] ?? "";
      }
      setValue(value);
    }
  }, [currentGridLine?.line?.[keyNameFromLine]]);

  React.useEffect(() => {
    setSearchText("");
    if (openPicker) {
      searchRef.current.focus();
      const clickListener = (e) => {
        const cliii = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!cliii) {
          setOpenPicker(false);
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handlePicker = async (e) => {
    e.stopPropagation();
    if (!openPicker) {
      setOpenPicker(true);
      setIsFocused(true);
      setPickerLoading(true);
      console.log(element);
      const editor = cleanEditor(tabID, model, element, grid);
      setEditor(editor);
      const GetSelectorData = await getSelectorData({ editor, value: "", tabID });
      if (GetSelectorData.success) {
        if (element.selectorVF != "") {
          var vf = element.selectorVF.split("|")[element.selectorVF.split("|").length - 1].split(".")[1];
          setReturnIndex(
            GetSelectorData.model.findIndex((object) => object.name == vf.split("_")[vf.split("_").length - 1])
          );
        } else {
          if (key.indexOf("_") > 0) {
            setReturnIndex(
              GetSelectorData.model.findIndex((object) => object.name == key.split("_")[key.split("_").length - 1])
            );
          } else {
            setReturnIndex(GetSelectorData.model.findIndex((object) => object.name == element.editor.split("|")[2]));
          }
        }
        setKeyIndex(GetSelectorData.model.findIndex((object) => object.name == element.editor.split("|")[2]));
        setSelectorData(GetSelectorData);
      } else {
        setSelectorData({ success: false });
        setError(GetSelectorData.error);
      }
      setPickerLoading(false);
    } else {
      setOpenPicker(false);
      setIsFocused(false);
    }
  };

  const handleClear = () => {
    setOpenPicker(false);
    setIsFocused(false);
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      if (objectState == "view") {
        !grid && dispatch(changeToBuffer({ tabID }));
        !grid && dispatch(setObjectState({ value: "edit", tabID }));
      }
      handleCalculate(table, key, "");
    }
  };

  const handleClickOnRow = async (row) => {
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: row[keyIndex] }));
      setOpenPicker(false);
      setIsFocused(false);
    } else if (screenState == "form") {
      if (!grid) {
        if (element.selectorVF != "") {
          const selectorArray = element.selectorVF.split("|");
          const splitted = selectorArray[selectorArray.length - 1].split(".");
          const tableName = splitted[0];
          const fieldName = splitted[1];
          if (objectState == "view") {
            dispatch(changeToBuffer({ tabID }));
            dispatch(setObjectState({ value: "edit", tabID }));
          }
          dispatch(setChange({ tabID, table: tableName, key: fieldName, value: row[returnIndex] }));
        }
        handleCalculate(table, key, row[keyIndex]);
      } else {
        dispatch(
          setGridChange({
            tabID,
            name: grid.model,
            key,
            value: row[returnIndex],
          })
        );
        dispatch(
          setGridChange({
            tabID,
            name: grid.model,
            key: keyNameFromLine,
            value: row[keyIndex],
          })
        );
        selectorData.model.map((item, index) => {
          dispatch(
            setGridChange({
              tabID,
              name: grid.model,
              key: `${underScore1}_${underScore2}_${item.name}`,
              value: row[index],
            })
          );
        });

        // dispatch(setLoading({ show: true, tabID }));
        // var filters = editor.split("|")[4];
        // var keyvalue = row[0];
        // var resultfields = [...new Set(selectorData.model.map((i) => i.name))];
        // const selectorFields = await getSelectorFields({
        //   filters,
        //   tablename,
        //   keyname,
        //   keyvalue,
        //   resultfields: resultfields.join(","),
        // });
        // if (selectorFields.success) {
        //   if (!grid) {
        //     if (objectState == "view") {
        //       dispatch(changeToBuffer({ tabID }));
        //       dispatch(setObjectState({ value: "edit", tabID }));
        //     }
        //   }
        //   Object.keys(selectorFields.rows[0]).forEach((key) => {
        //     if (grid) {
        //       dispatch(
        //         setGridChange({
        //           tabID,
        //           name: grid.model,
        //           key: `${underScore1}_${underScore2}_${key}`,
        //           value: selectorFields.rows[0][key],
        //         })
        //       );
        //     } else {
        //       dispatch(
        //         setChange({
        //           tabID,
        //           table,
        //           key: `${underScore1}_${underScore2}_${key}`,
        //           value: selectorFields.rows[0][key],
        //         })
        //       );
        //     }
        //   });
        //   if (!grid) {
        //     handleCalculate(table, keyNameFromLine, selectorFields.rows[0]?.[keyname]);
        //   } else {
        //     dispatch(
        //       setGridChange({
        //         tabID,
        //         name: grid.model,
        //         key: keyNameFromLine,
        //         value: selectorFields.rows[0][keyname],
        //       })
        //     );
        //   }
        //   setOpenPicker(false);
        //   setIsFocused(false);
        //   // setViewValue(value);
        //   // setKeyValue(selectorFields.data.rows[0][keyname]);
        //   // setLoading(false);
        //   // setOpenModal(false);
        // } else {
        //   dispatch(setError({ show: true, message: selectorFields.error }));
        // }
        // dispatch(setLoading({ show: false, tabID }));
      }
      setOpenPicker(false);
      setIsFocused(false);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    if (selectorData?.success) {
      clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(
          async () => {
            if (value.length >= 3 || value == "") {
              setPickerLoading(true);
              try {
                const GetSelectorData = await getSelectorData({ value, editor, tabID });
                if (GetSelectorData.success) {
                  setSelectorData(GetSelectorData);
                } else {
                  setSelectorData({ success: false });
                  setError(GetSelectorData.error);
                }
              } catch (error) {
                console.log(error);
              } finally {
                setPickerLoading(false);
              }
            }
          },
          value == "" ? 0 : 1000
        )
      );
    }
  };

  return (
    <div className="s1selector w-100" style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}>
      <div className="inputGroup">
        <label
          className="text-truncate"
          htmlFor={element.index}
          style={{
            color:
              value != "" && root && element.redirector && element.redirector != "" ? "rgb(64, 180, 252)" : undefined,
            cursor: value != "" && root && element.redirector && element.redirector != "" ? "pointer" : "default",
          }}
          onClick={value != "" && root && element.redirector && element.redirector != "" ? handleRedirect : undefined}
        >
          {`${element.caption}:`}
        </label>
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1 }}
          role="button"
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              role="button"
              className={`w-100${
                element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && commandParams.readonly)}
              readOnly={true}
              value={value}
              name={element.name}
              onClick={
                !element.readOnly || (screenState == "form" && commandParams.readonly) ? handlePicker : undefined
              }
            />
            {value != "" &&
              (clear || isFocused) &&
              !(element.readOnly || (screenState == "form" && commandParams.readonly)) && (
                <div onClick={handleClear}>
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
          </div>
          <div
            role="button"
            style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
            onClick={
              !(element.readOnly || (screenState == "form" && commandParams.readonly)) ? handlePicker : undefined
            }
          >
            <BsChevronDown
              className={element.readOnly || (screenState == "form" && commandParams.readonly) ? "disabled" : ""}
              color="rgb(64, 180, 252)"
              size="20px"
            />
          </div>
        </div>
      </div>
      {openPicker && (
        <div
          className="input-picker-div"
          ref={pickerRef}
          style={{
            left:
              boundRef.current.getBoundingClientRect().left + 400 > window.innerWidth
                ? boundRef.current.getBoundingClientRect().right - 400
                : boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 300 > window.innerHeight
                ? 300 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "300px",
            overflow: "hidden",
          }}
        >
          {pickerLoading && <PickerSpinner />}
          <div className="bg-light">
            <div className="inputGroup m-0 gap-1" style={{ borderBottom: "1px solid black" }}>
              <div className="ms-1 d-flex justify-content-center align-items-center">
                <BsSearch color="rgb(64, 180, 252)" />
              </div>
              <input
                ref={searchRef}
                style={{ height: "30px" }}
                placeholder="Αναζήτηση"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
              {searchText != "" && (
                <div
                  onClick={() => {
                    handleSearch("");
                  }}
                >
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
            </div>
          </div>
          <div
            className="input-picker bg-light w-100 h-100"
            style={{
              overflow: "auto",
            }}
          >
            {selectorData.success ? (
              <table className="input-picker-table w-100" ref={tableRef}>
                <tbody>
                  {Object.keys(selectorData.data).map((key, index) => {
                    return (
                      <tr
                        key={index}
                        className={`input-picker-table-row${
                          selectedRow == selectorData.data[key][keyIndex]
                            ? " input-picker-table-row-selected hovered"
                            : ""
                        }`}
                        onClick={() => handleClickOnRow(selectorData.data[key])}
                      >
                        {selectorData.model.slice(1).map((item, i) => {
                          return (
                            <td className="py-1 px-2" key={i} style={{ height: "40px" }}>
                              {selectorData.data[key][i + 1]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">{error}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default S1Selector;
