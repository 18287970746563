import React from "react";
import { FaLayerGroup } from "react-icons/fa";

const Group = () => {
  return (
    <div className="tab-list-context-menu-action" role="button">
      <div className="d-flex align-items-center">
        <FaLayerGroup className="me-1" />
        <span>Ομαδοποίηση</span>
      </div>
    </div>
  );
};

export default Group;
