export const _setBrowserInfo = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].browserInfo = action.payload.value;
    if (!state.tabs[action.payload.tabID].tableColumns) {
      const hiddenColumns = [...(state.tabs[action.payload.tabID].hiddenColumns ?? [])];
      const columns = action.payload.value.fields
        ? action.payload.value.fields.map((field) => {
            const index = action.payload.value.columns.findIndex((col) => col.dataIndex == field.name);
            if (index > -1)
              return {
                ...action.payload.value.columns[index],
                Header: action.payload.value.columns[index]?.header,
                Footer: action.payload.value.sums[index],
                accessor: (action.payload.value.columns[index]?.dataIndex ?? "").replace(".", "_"),
                canReveal: true,
              };
            else {
              hiddenColumns.push(field.name.replace(".", "_"));
              return {
                Header: field.name,
                dataIndex: field.name,
                accessor: field.name.replace(".", "_"),
                hidden: true,
                canReveal: false,
              };
            }
          })
        : [];
      state.tabs[action.payload.tabID].tableColumns = columns;
      if (!state.tabs[action.payload.tabID].hiddenColumns)
        state.tabs[action.payload.tabID].hiddenColumns = hiddenColumns;
    }
    const columns = action.payload.value.fields;

    const grouping = { toogle: false, hasGroups: false };
    if (columns) {
      if ((action.payload.value?.gfilters?.fields ?? []).length > 0) {
        grouping.toogle = state.tabs[action.payload.tabID]?.group?.toogle ?? true;
        grouping.hasGroups = true;
        grouping.filters = action.payload.value.gfilters;
        action.payload.value.gfilters.fields.map((grouField) => {
          const index = columns.findIndex(
            (column) =>
              column?.dataIndex &&
              column.dataIndex.split(".").length > 1 &&
              column.dataIndex.split(".")[1] == grouField.name
          );
          if (index > -1) columns[index].groupBy = true;
        });
      }
    }

    state.tabs[action.payload.tabID].group = grouping;

    const data = action.payload.value.rows
      ? action.payload.value.rows.map((row) => {
          const obj = {};
          state.tabs[action.payload.tabID].browserInfo.fields.forEach((field) => {
            obj[field.name.replace(".", "_")] = row[state.tabs[action.payload.tabID].browserInfo.fields.indexOf(field)];
          });
          return obj;
        })
      : [];
    state.tabs[action.payload.tabID].tableData = data;
    state.tabs[action.payload.tabID].tableBrowserData = data;
  }
};

export const _setTableData = (state, action) => {
  state.tabs[action.payload.tabID].tableData = action.payload.value;
};

export const _showTHeadContextMenu = (state, action) => {
  state.contextMenu.thead = action.payload;
};

export const _setSelectedRow = (state, action) => {
  state.tabs[action.payload.tabID].browserData = {
    ...state.tabs[action.payload.tabID].browserData,
    selectedRow: action.payload.value,
  };
};

export const _setColumnVisibility = (state, action) => {
  state.tabs[action.payload.tabID].tableColumns[action.payload.column].hidden = action.payload.value;
  if (action.payload.value) {
    state.tabs[action.payload.tabID].hiddenColumns.push(action.payload.accessor);
  } else {
    const index = state.tabs[action.payload.tabID].hiddenColumns.indexOf(action.payload.accessor);
    state.tabs[action.payload.tabID].hiddenColumns.splice(index, 1);
  }
};

export const _setBrowserFlexLayout = (state, action) => {
  state.tabs[action.payload.tabID].flexLayout = action.payload.value;
};

export const _resetBrowserLayout = (state, action) => {
  state.tabs[action.payload.tabID].resetColumnWidths++;
};

export const _canCalendar = (state, action) => {
  if (state.tabs[action.payload.tabID]) state.tabs[action.payload.tabID].canCalendar = true;
};

export const _setBrowserView = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].calendar = undefined;
    state.tabs[action.payload.tabID].browserView = action.payload.value;
  }
};

export const _groupToogle = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].group = {
      ...state.tabs[action.payload.tabID].group,
      toogle: action.payload.value,
    };
  }
};
