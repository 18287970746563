import React from "react";
import { TiDeleteOutline } from "react-icons/ti";
import { BsCalendar3 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setDateRanges, setFilter } from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters } from "../../../redux/selectors";
import getValueFromFilterArray from "../../functions/getValueFromFilterArray";

const S1DateRange = ({ element, tabID, screenState, parentIndex }) => {
  const isDateTime = element.dataType == "DateTime";
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));
  const dateranges = useSelector((state) => state.systemParams.dateranges);

  const dateRef = React.useRef(null);

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");

  const handleClear = () => {
    setValue("");
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
      dispatch(setDateRanges({ key: parentIndex, value: undefined, tabID }));
    } else {
      console.log("dispatch se " + screenState);
    }
  };

  React.useEffect(() => {
    var value = "";
    const getDtValue = () => {
      if (element.value != "") {
        const dtIndex = element.value.split(":")[1].slice(-2);
        const fromOrTo = element.value.split(":")[1].substring(0, 1) == "1" ? "from" : "to";
        value = dateranges?.[`1${dtIndex}`]?.[fromOrTo] ?? "";
      } else {
        value = element.value;
      }
    };
    if (screenState == "dialog") {
      getDtValue();

      value = filters?.text ? getValueFromFilterArray(table, key) : value;
      value = value != "" ? dateConverter(value) : "";
      setValue(value);
      dispatch(setFilter({ tabID, table, key, value: isDateTime ? dateTimeConverter(value) : dateConverter(value) }));
    }
  }, []);

  React.useEffect(() => {
    if (filters?.daterange?.[parentIndex]) {
      const dtIndex = filters?.daterange?.[parentIndex].slice(-2);
      var fromOrTo = "";
      if (element.name.indexOf("_TO") > -1) {
        fromOrTo = "to";
      } else {
        if (element.value.split(":").length > 1) {
          fromOrTo = element.value.split(":")[1].substring(0, 1) == "1" ? "from" : "to";
        } else {
          fromOrTo = "from";
        }
      }
      var value = dateranges?.[`1${dtIndex}`]?.[fromOrTo];
      handleChange(value == "1899-12-30" ? "" : value);
      setValue(value == "1899-12-30" ? "" : dateConverter(value));
    }
  }, [filters?.daterange?.[parentIndex]]);

  const handleChange = (value) => {
    setValue(value);
    dispatch(setFilter({ tabID, table, key, value: isDateTime ? dateTimeConverter(value) : dateConverter(value) }));
  };

  return (
    <div className="s1daterange w-100" style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}>
      <div className="inputGroup">
        <label className="text-truncate" htmlFor={element.index}>
          {`${element.caption}:`}
        </label>
        <div
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              ref={dateRef}
              type="date"
              className={`w-100${value != "" ? " date-input-has-value" : ""}`}
              name={element.name}
              disabled={element.readOnly || (screenState == "form" && !commandParams.readonly)}
              readOnly={element.readOnly || (screenState == "form" && !commandParams.readonly)}
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onClick={() => {
                dateRef.current.showPicker();
                dateRef.current.click();
                dateRef.current.focus();
              }}
            />
            {value != "" && clear && (
              <div onClick={handleClear}>
                <TiDeleteOutline size="30px" role="button" />
              </div>
            )}
          </div>
          {!element.readOnly ||
            (screenState == "form" && !commandParams.readonly && (
              <div
                style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
                onClick={() => {
                  dateRef.current.showPicker();
                  dateRef.current.click();
                  dateRef.current.focus();
                }}
              >
                <BsCalendar3 color="rgb(64, 180, 252)" size="20px" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default S1DateRange;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};

const dateTimeConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return `${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-")} 00:00:00`;
  } else {
    return value;
  }
};
