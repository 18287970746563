import React from "react";
import "./CustomTooltip.css";

const CustomTooltip = ({ title, children }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const toolRef = React.useRef(null);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      ref={toolRef}
      className="custom-tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div
          className="custom-tooltip"
          style={{
            top: toolRef?.current?.getBoundingClientRect()?.top + toolRef?.current?.offsetHeight / 2,
            left: toolRef?.current?.getBoundingClientRect()?.right + 1,
          }}
        >
          {title}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
