import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentGridLine, getNewData, getObjectState } from "../../../redux/selectors";
import {
  changeToBuffer,
  deleteLineFromGrid,
  setCurrentDetailLine,
  setObjectState,
  triggerGridChange,
} from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";

const DeleteLineModal = ({ show, setShow, tabID, element }) => {
  const dispatch = useDispatch();
  const grid = useSelector((state) => getCurrentGridLine(state, tabID, element.model));
  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const handleAccept = async () => {
    setShow(false);
    if (objectState == "view") {
      dispatch(changeToBuffer({ tabID }));
      dispatch(setObjectState({ value: "edit", tabID }));
    }
    var gridLines = newData?.[element.model] ?? [];
    gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
    dispatch(deleteLineFromGrid({ tabID, name: element.model, lineIndex: grid.index }));
    await calculate(tabID);
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
  };
  return (
    <Modal backdrop="static" animation={false} show={show} centered size="sm" onHide={() => setShow(false)}>
      <Modal.Header className="p-2 ps-3 bg-light" closeButton style={{ userSelect: "none" }}>
        Ερώτηση
      </Modal.Header>
      <Modal.Body className="d-flex flex-column bg-light" style={{ userSelect: "none" }}>
        Επιβεβαίωση διαγραφής;
      </Modal.Body>
      <Modal.Footer className="p-0 bg-light">
        <Button type="submit" onClick={handleAccept}>
          Επιβεβαίωση
        </Button>
        <Button onClick={() => setShow(false)}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteLineModal;
