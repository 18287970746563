import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { killAllTabs, killAllTabsExceptOne, killTab, showTabContextMenu } from "../../redux/features/ui/uiSlice";

const TabsContextMenu = () => {
  const tab = useSelector((state) => state.ui.contextMenu.tab);
  const [menuWidth, setMenuWidth] = React.useState(0);
  const menuRef = React.useRef(null);
  const dispatch = useDispatch();

  const closeThisOne = () => {
    dispatch(showTabContextMenu({ show: false }));
    dispatch(killTab(tab.tab));
  };

  const closeAllButLeaveThisOpen = () => {
    dispatch(showTabContextMenu({ show: false }));
    dispatch(killAllTabsExceptOne(tab.tab));
  };

  const closeAll = () => {
    dispatch(showTabContextMenu({ show: false }));
    dispatch(killAllTabs());
  };

  React.useEffect(() => {
    setMenuWidth(menuRef.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    if (tab.show) {
      const clickListener = (e) => {
        if (!menuRef.current.contains(e.target)) {
          dispatch(showTabContextMenu({ show: false }));
        }
      };
      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [tab.show]);

  return (
    <div
      ref={menuRef}
      className="tab-list-context-menu select-none"
      style={{
        top: tab.y,
        left: tab.x + menuWidth > window.innerWidth ? window.innerWidth - menuWidth : tab.x,
      }}
    >
      <div className="tab-list-context-menu-action" role="button" onClick={closeThisOne}>
        Κλείσιμο καρτέλας
      </div>
      <div className="tab-list-context-menu-action" role="button" onClick={closeAllButLeaveThisOpen}>
        Κλείσιμο των άλλων καρτελών
      </div>
      <div className="tab-list-context-menu-action" role="button" onClick={closeAll}>
        Κλείσιμο όλων των καρτελών
      </div>
    </div>
  );
};

export default TabsContextMenu;
