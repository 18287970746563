import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeToBuffer, setChange, setFilter, setObjectState } from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getObjectState, getRecData } from "../../../redux/selectors";

const S1TextArea = ({ element, tabID, screenState }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, []);

  React.useEffect(() => {
    if (screenState == "form") {
      setValue(data?.[table]?.[0]?.[key] ?? "");
    }
  }, [data?.[table]?.[0]?.[key]]);

  const handleChange = (e) => {
    setIsFocused(false);
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: value }));
    } else if (screenState == "form") {
      if ((data?.[table]?.[0]?.[key] ?? "") != e.target.value) {
        if (objectState == "view") {
          dispatch(changeToBuffer({ tabID }));
          dispatch(setObjectState({ value: "edit", tabID }));
        }
        dispatch(setChange({ tabID, table, key, value: value }));
      }
    }
  };

  return (
    <div
      className={`s1textarea w-100${element.visible ? "" : " hidden"}`}
      style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
    >
      <legend style={{ fontSize: "1.2rem", borderBottom: "2px solid black" }}>{element.caption}</legend>
      <div className="text-area-input-group">
        <div></div>
        <div
          className={`textarea-with-buttons${isFocused ? " whole-focus" : ""}${
            element.required && value == "" ? " whole-required" : ""
          }`}
          style={{ flexGrow: 1 }}
        >
          <div className="d-flex w-100">
            <textarea
              className={`w-100${element.readOnly || commandParams.readonly ? " disabled" : ""}`}
              type="textarea"
              rows={8}
              style={{ resize: "none", padding: "0.2rem" }}
              disabled={element.readOnly || commandParams.readonly}
              readOnly={element.readOnly || commandParams.readonly}
              name={element.name}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default S1TextArea;
