import React from "react";
import FirstDay from "../custom inputs/FirstDay";
import BussinessDays from "../custom inputs/BussinessDays";
import Slots from "../custom inputs/Slots";
import CalendarStart from "../custom inputs/CalendarStart";
import CalendarEnd from "../custom inputs/CalendarEnd";
import CalendarColour from "../custom inputs/CalendarColor";

const Calendar = () => {
  return (
    <div className="d-flex flex-column w-100">
      <FirstDay />
      <BussinessDays />
      <Slots />
      <CalendarStart />
      <CalendarEnd />
      <CalendarColour />
    </div>
  );
};

export default Calendar;
