import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGadgetBrowserInfo, getOutputFormats, getSN } from "../../../../../redux/selectors";
import { setLoading } from "../../../../../redux/features/portal/portalSlice";
import print from "../../../../../services/print";
import { Dropdown } from "react-bootstrap";
import { CgPrinter } from "react-icons/cg";
import { setError } from "../../../../../redux/features/modals/modalsSlice";
import ModalDownload from "../../ModalDownload";

const Print = ({ id }) => {
  const dispatch = useDispatch();
  const serialnumber = useSelector((state) => getSN(state));
  const outputFormats = useSelector((state) => getOutputFormats(state)?.list);
  const loading = useSelector((state) => state.portal.gadgets[id].loading);

  const [printURL, setPrintURL] = React.useState(undefined);
  const [fileName, setFileName] = React.useState("");
  const [format, setFormat] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  const browserInfo = useSelector((state) => getGadgetBrowserInfo(state, id));

  const canCommit = browserInfo?.success && browserInfo?.totalcount > 0 && !loading;

  const handlePrintBrowser = async (format) => {
    dispatch(setLoading({ value: true, id }));
    const printDoc = await print({ reqID: browserInfo.reqID, format });
    if (printDoc.success) {
      setPrintURL(`https://${serialnumber}.oncloud.gr/s1services/?filename=${printDoc.filename}`);
      setFileName(printDoc.filename.replace(/[<>:"/\\|?*\x00-\x1F]/g, "_"));
      setFormat(format);
      setShowModal(true);
    } else {
      dispatch(setError({ show: false, message: printDoc.error }));
    }
    dispatch(setLoading({ value: false, id }));
  };

  const PrintDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      className="d-flex align-items-center justify-content-center user-select-none"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Dropdown className="d-flex" align="start">
        <Dropdown.Toggle as={PrintDropDown}>
          <div
            className="d-flex align-items-center justify-content-start"
            style={{
              userSelect: "none",
              cursor: canCommit ? "pointer" : "default",
              opacity: canCommit ? 1 : 0.5,
            }}
          >
            <CgPrinter />
          </div>
        </Dropdown.Toggle>
        {canCommit && (
          <Dropdown.Menu className="shadow">
            {Object.keys(outputFormats).map((key) => {
              return (
                key != "XPS" && (
                  <Dropdown.Item key={key} onClick={() => handlePrintBrowser(key)}>
                    {{
                      "Ascii file": "Ascii",
                      "PDF file": "PDF",
                      "Html image file": "Html",
                      "Spreadsheet file": "Excel",
                      "Document file": "Word",
                    }[outputFormats[key]] ?? outputFormats[key]}
                  </Dropdown.Item>
                )
              );
            })}
          </Dropdown.Menu>
        )}
        <ModalDownload
          show={showModal}
          setShow={setShowModal}
          url={printURL}
          fileName={fileName}
          format={format}
          id={id}
        />
      </Dropdown>
    </div>
  );
};

export default Print;
