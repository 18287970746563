import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const ModalWarningForBlock = () => {
  const [isBlocked, setIsBlocked] = React.useState(false);
  const [blcdateDD, setBlcDate] = React.useState();

  const session = useSelector((state) => state.session);

  React.useEffect(() => {
    const today = new Date(`${formatUSDate(new Date())}`);
    const blckdate = new Date(session.blcdate);
    var Difference_In_Time = blckdate.getTime() - today.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setBlcDate(formatDate(blckdate));
    setIsBlocked(Difference_In_Days <= 15 && Difference_In_Days >= 0);
  }, []);

  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={isBlocked}
      animation={false}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Body className="d-flex justify-content-start align-items-center" style={{ userSelect: "none" }}>
        <div>
          Η άδεια χρήσης του λογισμικού σας λήγει την{" "}
          <u>
            <span style={{ fontWeight: "bold" }}>{blcdateDD}</span>
          </u>
          .
          <br />
          Παρακαλούμε, επικοινωνήστε έγκαιρα με την DayOne στο: <a href="tel:2165005060">216 5005060</a> για ανανέωση.
          <br />
          Σημειώνεται πως με την λήξη, παύει αυτομάτως η πρόσβαση στην Web πλατφόρμα.
        </div>{" "}
      </Modal.Body>
      <Modal.Footer className="p-0 d-flex align-items-center justify-content-center">
        <Button variant="primary" onClick={() => setIsBlocked(false)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWarningForBlock;

const formatDate = (date) => {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("/");
};

const formatUSDate = (date) => {
  return [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()), date.getFullYear()].join("/");
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};
