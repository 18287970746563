import React from "react";

const Pages = ({ pageIndex, gotoPage, pageOptions }) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ userSelect: "none" }}>
      <span>Σελίδα</span>
      <input
        type="number"
        autoComplete="off"
        value={pageIndex + 1}
        onChange={(e) => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0;
          gotoPage(page);
        }}
        style={{ width: "50px", margin: "0 0.5rem", textAlign: "center" }}
      />
      <span style={{ textWrap: "nowrap" }}> {`από ${pageOptions.length}`}</span>
    </div>
  );
};

export default Pages;
