import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setBrowserInfo,
  setGetData,
  setLoading,
  setObjectState,
  setScreenState,
} from "../../../../../redux/features/ui/uiSlice";
import { HiSearch } from "react-icons/hi";
import { getBrowserInfo, getObjectState } from "../../../../../redux/selectors";
import serviceGetBrowserInfo from "../../../../../services/getBrowserInfo";
import ModalSaveRecord from "./ModalSaveRecord";

const Browser = ({ tabID }) => {
  const dispatch = useDispatch();
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const [show, setShow] = React.useState(false);

  const handleGoToBrowser = () => {
    if (objectState == "view") {
      goBack();
    } else {
      setShow(true);
    }
  };

  const goBack = async () => {
    if (!browserInfo) {
      dispatch(setLoading({ show: true, tabID }));
      const info = await serviceGetBrowserInfo(tabID);
      dispatch(setBrowserInfo({ value: info, tabID }));
      dispatch(setLoading({ show: false, tabID }));
    }
    dispatch(setScreenState({ value: "browser", tabID }));
    dispatch(setObjectState({ value: "view", tabID }));
    dispatch(setGetData({ value: undefined, tabID }));
  };

  return (
    <>
      <div
        onClick={handleGoToBrowser}
        className="d-flex align-items-center justify-content-start"
        style={{ cursor: "pointer" }}
      >
        <HiSearch size="1.1rem" className="me-1" />
        Λίστα
      </div>
      {show && (
        <ModalSaveRecord
          show={show}
          setShow={setShow}
          tabID={tabID}
          functionNo={() => {
            goBack();
            setShow(false);
          }}
        />
      )}
    </>
  );
};

export default Browser;
