import React from "react";
import { HiPrinter } from "react-icons/hi";
import { useSelector, useDispatch } from "react-redux";
import { getFormDesign, getObjectState, getRecData, getSN } from "../../../../../redux/selectors";
import ModalPrintRecord from "./ModalPrintRecord";
import printDoc from "../../../../../services/printDoc";
import { setError } from "../../../../../redux/features/modals/modalsSlice";
import { setLoading } from "../../../../../redux/features/ui/uiSlice";
import ModalDownload from "../browser/ModalDownload";

const PrintRecord = ({ tabID }) => {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const serialnumber = useSelector((state) => getSN(state));

  const data = useSelector((state) => getRecData(state, tabID));
  const [show, setShow] = React.useState(false);
  const [showDownload, setShowDownload] = React.useState(false);
  const [printURL, setPrintURL] = React.useState(undefined);
  const [fileName, setFileName] = React.useState("");
  const [format, setFormat] = React.useState("");

  const print = async (format, template) => {
    dispatch(setLoading({ tabID, show: true }));
    var key = formDesign?.key;
    var keyValue = data[`${key.split(".")[0]}`][0][`${key.split(".")[1]}`];

    const doc = await printDoc({ format, template, key: keyValue, tabID });
    if (doc.success) {
      setPrintURL(`https://${serialnumber}.oncloud.gr/s1services/?filename=${doc.filename}`);
      setFileName(doc.filename.replace(/[<>:"/\\|?*\x00-\x1F]/g, "_"));
      dispatch(setLoading({ show: false, tabID }));
      setFormat(format);
      setShowDownload(true);
    } else {
      dispatch(setError({ show: true, message: doc.error }));
    }
    dispatch(setLoading({ tabID, show: false }));
  };
  return (
    <>
      <div
        onClick={() => setShow(true)}
        className="d-flex align-items-center justify-content-start"
        style={{
          userSelect: "none",
          cursor: objectState == "view" ? "pointer" : "default",
          opacity: objectState == "view" ? 1 : 0.5,
        }}
      >
        <HiPrinter size="1.2rem" className="me-1" />
      </div>
      <ModalPrintRecord show={show} setShow={setShow} accept={print} tabID={tabID} />
      <ModalDownload
        show={showDownload}
        setShow={setShowDownload}
        url={printURL}
        fileName={fileName}
        tabID={tabID}
        format={format}
      />
    </>
  );
};

export default PrintRecord;
