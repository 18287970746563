import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBrowserFlexLayout, setColumnVisibility, showTHeadContextMenu } from "../../../../redux/features/ui/uiSlice";
import { AiFillCaretRight } from "react-icons/ai";
import { HiCheck, HiOutlineViewGridAdd } from "react-icons/hi";
import { CgArrowsShrinkH, CgArrowsMergeAltH } from "react-icons/cg";
import { getFlexLayout, getHiddenColumns, getTableColumns } from "../../../../redux/selectors";
import Sort from "./context menu/Sort";
import Columns from "./context menu/Columns";
import Group from "./context menu/Group";

const THeadContextMenu = () => {
  const dispatch = useDispatch();
  const thead = useSelector((state) => state.ui.contextMenu.thead);
  const flexLayout = useSelector((state) => getFlexLayout(state, thead.tabID));

  const menuRef = React.useRef(null);
  const [menuWidth, setMenuWidth] = React.useState(0);

  React.useEffect(() => {
    setMenuWidth(menuRef.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    if (thead.show) {
      const clickListener = (e) => {
        if (!menuRef.current.contains(e.target)) {
          dispatch(showTHeadContextMenu({ show: false }));
        }
      };
      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [thead.show]);

  return (
    <div
      ref={menuRef}
      className="tab-list-context-menu select-none"
      style={{
        top: thead.y,
        left: thead.x + menuWidth > window.innerWidth ? window.innerWidth - menuWidth : thead.x,
      }}
    >
      <Sort sort="up" />
      <Sort sort="down" />

      <Group />
      <hr
        style={{
          height: 0,
          margin: "0.5rem 0",
          overflow: "hidden",
          borderTop: "1px solid rgba(0,0,0,0.175)",
          opacity: 1,
        }}
        role="separator"
      />
      <div className="tab-list-context-menu-action" role="button">
        <div
          className="d-flex align-items-center"
          onClick={() => {
            dispatch(setBrowserFlexLayout({ tabID: thead.tabID, value: true }));
            dispatch(showTHeadContextMenu({ show: false }));
          }}
        >
          {flexLayout ? <HiCheck className="me-1" color="rgb(64, 180, 252)" /> : <CgArrowsShrinkH className="me-1" />}
          <span>Fit to screen</span>
        </div>
      </div>
      <div className="tab-list-context-menu-action" role="button">
        <div
          className="d-flex align-items-center"
          onClick={() => {
            dispatch(setBrowserFlexLayout({ tabID: thead.tabID, value: false }));
            dispatch(showTHeadContextMenu({ show: false }));
          }}
        >
          {flexLayout ? <CgArrowsMergeAltH className="me-1" /> : <HiCheck className="me-1" color="rgb(64, 180, 252)" />}

          <span>Fit to text</span>
        </div>
      </div>
      {/* <div className="tab-list-context-menu-action" role="button">
        <div
          className="d-flex align-items-center"
          onClick={() => {
            dispatch(resetBrowserLayout({ tabID: thead.tabID }));
            dispatch(showTHeadContextMenu({ show: false }));
          }}
        >
          <RxReset className="me-1" />
          <span>Reset columns</span>
        </div>
      </div> */}
      <hr
        style={{
          height: 0,
          margin: "0.5rem 0",
          overflow: "hidden",
          borderTop: "1px solid rgba(0,0,0,0.175)",
          opacity: 1,
        }}
        role="separator"
      />
      <Columns menuRef={menuRef} />
    </div>
  );
};

export default THeadContextMenu;
