import React from "react";
import { Button, Modal } from "react-bootstrap";
import { setLoading, setSettingsModal } from "../../../redux/features/modals/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import App from "../settings/App";
import CloudStorage from "../settings/CloudStorage";
import saveSettings from "../../../services/saveSettings";
import { setSettings } from "../../../redux/features/settings/settingsSlice";
import Calendar from "../settings/Calendar";

const ModalSettings = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.modals.settings);
  const draft = useSelector((state) => state.settings.draft);
  const hasSettingsInDB = useSelector((state) => state.settings.hasSettingsInDB);
  const wa = useSelector((state) => state.systemParams?.wa);
  const [activePanel, setActivePanel] = React.useState("app");

  const handleSave = async () => {
    dispatch(setSettingsModal(false));
    dispatch(setSettings(draft));
    dispatch(setLoading(true));
    const setData = await saveSettings({
      key: hasSettingsInDB ? wa : "",
      data: { CCCD1WEBCRMSETTINGS: [hasSettingsInDB ? draft : { ...draft, CCCD1WEBCRMSETTINGS: wa }] },
    });
    dispatch(setLoading(false));
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      keyboard={false}
      animation={false}
      size="lg"
      onHide={() => dispatch(setSettingsModal(false))}
      centered
    >
      <Modal.Header className="p-2 ps-3" closeButton style={{ userSelect: "none" }}>
        Settings
      </Modal.Header>
      <Modal.Body className="d-flex flex-column" style={{ minHeight: "300px" }}>
        <nav className="overflow-auto d-flex">
          <div
            className="nav nav-tabs"
            role="tablist"
            style={{ inlineSize: "max-content", flex: 1, borderBottom: "2px solid black", flexWrap: "nowrap" }}
          >
            <div
              className={`form-tab text-truncate px-1 ${activePanel == "app" ? "active" : ""}`}
              onClick={() => setActivePanel("app")}
              style={{
                userSelect: "none",
                cursor: "pointer",
                display: "flex",
                borderBottom: activePanel == "app" ? "2px solid rgb(64,180,252)" : "none",
                marginBottom: activePanel == "app" ? "-2px" : "0",
                fontSize: activePanel == "app" ? "1.3rem" : "1.1rem",
                fontWeight: activePanel == "app" ? 500 : 400,
                alignItems: "flex-end",
              }}
            >
              Εφαρμογή
            </div>
            <div
              className={`form-tab text-truncate px-1 ${activePanel == "calendar" ? "active" : ""}`}
              onClick={() => setActivePanel("calendar")}
              style={{
                userSelect: "none",
                cursor: "pointer",
                display: "flex",
                borderBottom: activePanel == "calendar" ? "2px solid rgb(64,180,252)" : "none",
                marginBottom: activePanel == "calendar" ? "-2px" : "0",
                fontSize: activePanel == "calendar" ? "1.3rem" : "1.1rem",
                fontWeight: activePanel == "calendar" ? 500 : 400,
                alignItems: "flex-end",
              }}
            >
              Ημερολόγιο
            </div>
            <div
              className={`form-tab text-truncate px-1 ${activePanel == "cloud" ? "active" : ""}`}
              onClick={() => setActivePanel("cloud")}
              style={{
                userSelect: "none",
                cursor: "pointer",
                display: "flex",
                borderBottom: activePanel == "cloud" ? "2px solid rgb(64,180,252)" : "none",
                marginBottom: activePanel == "cloud" ? "-2px" : "0",
                fontSize: activePanel == "cloud" ? "1.3rem" : "1.1rem",
                fontWeight: activePanel == "cloud" ? 500 : 400,
                alignItems: "flex-end",
              }}
            >
              Cloud Storage
            </div>
          </div>
        </nav>
        <div className="tab-content">
          <div
            className={`mt-2 ${activePanel == "app" ? "active show" : ""}`}
            role="tabpanel"
            style={{ display: activePanel == "app" ? "flex" : "none" }}
          >
            <App />
          </div>
          <div
            className={`mt-2 ${activePanel == "calendar" ? "active show" : ""}`}
            role="tabpanel"
            style={{ display: activePanel == "calendar" ? "flex" : "none" }}
          >
            <Calendar />
          </div>
          <div
            className={`mt-2 ${activePanel == "cloud" ? "active show" : ""}`}
            role="tabpanel"
            style={{ display: activePanel == "cloud" ? "flex" : "none" }}
          >
            <CloudStorage />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center p-0">
        <Button onClick={handleSave} disabled={Object.keys(draft).length == 0}>
          Αποθήκευση
        </Button>
        <Button onClick={() => dispatch(setSettingsModal(false))}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSettings;
