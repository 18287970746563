import React from "react";
import { AiFillWarning } from "react-icons/ai";

const ErrorDisplay = ({ error }) => {
  return (
    <center className="d-flex w-100  flex-column">
      <div style={{ marginTop: "5rem" }}>
        <span style={{ fontSize: "1.8rem", fontWeight: "bold", color: "#4A4A4A" }}>Σφάλμα!</span>
        <br />
        <AiFillWarning size="128px" color="#4A4A4A" />
        <br />
        <div style={{ height: "300px", width: "60%", color: "#4A4A4A" }}>{error}</div>
      </div>
    </center>
  );
};

export default ErrorDisplay;
