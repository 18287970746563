import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBrowserInfo, getSortField } from "../../../../../redux/selectors";
import {
  setBrowserInfo,
  setLoading,
  setSortField,
  showTHeadContextMenu,
} from "../../../../../redux/features/ui/uiSlice";
import serviceGetBrowserInfo from "../../../../../services/getBrowserInfo";
import { BiSortUp, BiSortDown } from "react-icons/bi";
import axios from "axios";

const THead = React.forwardRef(({ headerGroups, tabID, rows }, HeadRef) => {
  const dispatch = useDispatch();
  const sortField = useSelector((state) => getSortField(state, tabID));
  const settings = useSelector((state) => state.settings);
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));

  const handleSort = async (column) => {
    dispatch(setSortField({ tabID: tabID, value: sortField == column ? "*" + column : column }));
    dispatch(setLoading({ show: true, tabID }));
    const browserInfo = await serviceGetBrowserInfo(tabID);
    dispatch(setBrowserInfo({ value: browserInfo, tabID }));
    dispatch(setLoading({ show: false, tabID }));
  };

  const handleRightClick = (e, column) => {
    e.preventDefault();
    dispatch(showTHeadContextMenu({ show: true, x: e.pageX, y: e.pageY, column, tabID }));
  };

  return (
    <div className="browser-table-thead" ref={HeadRef}>
      {headerGroups.map((headerGroup) => {
        return (
          <div
            className="browser-table-tr"
            {...headerGroup.getHeaderGroupProps()}
            style={
              rows.getRowProps
                ? {
                    ...rows?.getRowProps()?.style,
                    display: "flex",
                  }
                : {
                    display: "flex",
                  }
            }
          >
            {headerGroup.headers.map((column, index) => {
              const field = browserInfo.fields.filter((item) => item.name == column.dataIndex);
              return (
                <div
                  {...column.getHeaderProps()}
                  className="browser-table-th text-truncate"
                  onContextMenu={(e) => handleRightClick(e, column.dataIndex)}
                  key={index}
                  style={{
                    ...column.getHeaderProps().style,
                    // display: flexLayout == true ? "flex" : column.getHeaderProps().style.display,
                    backgroundColor:
                      sortField && sortField.indexOf(column.dataIndex) > -1
                        ? "var(--bs-gray-600)"
                        : "Var(--bs-gray-dark)",
                  }}
                >
                  <div
                    className="d-flex align-items-center text-truncate"
                    onClick={() =>
                      settings.app.clickToSort == 1 &&
                      column.id.indexOf("FLD-") < 0 &&
                      field[0].type != "image" &&
                      handleSort(column.dataIndex)
                    }
                    style={{
                      justifyContent: column?.align ?? "flex-start",
                    }}
                  >
                    {column.render("Header")}
                    {sortField && sortField.indexOf(column.dataIndex) > -1 ? (
                      sortField.indexOf("*") > -1 ? (
                        <BiSortDown className="ms-1" size="1rem" style={{ minHeight: "1rem", minWidth: "1rem" }} />
                      ) : (
                        <BiSortUp className="ms-1" size="1rem" style={{ minHeight: "1rem", minWidth: "1rem" }} />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    {...column.getResizerProps()}
                    className={`browser-table-resizer ${column.isResizing ? "isResizing" : ""}`}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
});

export default THead;
