var data = {};
const recursive = (element) => {
  if (element.items ?? [].length > 0) {
    element.items.forEach((item) => recursive(item));
  } else {
    if (element.value) {
      data = {
        ...data,
        [element.name.split(".")[0]]: {
          ...data?.[element.name.split(".")[0]],
          [element.name.split(".")[1]]: element.value,
        },
      };
    }
  }
};

export const _setDialog = (state, action) => {
  data = {};
  console.log(data);
  if (state.tabs[action.payload.tabID]) {
    if (action.payload.value.success) {
      action.payload.value.form.forEach((element) => recursive(element));
    }
    console.log(data);

    state.tabs[action.payload.tabID].dialog = {
      ...action.payload.value,
      filters: {
        data,
      },
    };
  }
};

export const _setFilter = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].dialog = {
      ...state.tabs?.[action.payload.tabID]?.dialog,
      filters: {
        ...state.tabs?.[action.payload.tabID]?.dialog?.filters,
        data: {
          ...state.tabs[action.payload.tabID]?.dialog?.filters?.data,
          [action.payload.table]: {
            ...state.tabs[action.payload.tabID]?.dialog?.filters?.data?.[action.payload.table],
            [action.payload.key]: action.payload.value,
          },
        },
      },
    };
  }
};

export const _setFilterText = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].dialog = {
      ...state.tabs[action.payload.tabID]?.dialog,
      filters: {
        ...state.tabs[action.payload.tabID]?.dialog?.filters,
        text: action.payload.value,
      },
    };
  }
};

export const _setDateRanges = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].dialog = {
      ...state.tabs[action.payload.tabID].dialog,
      filters: {
        ...state.tabs[action.payload.tabID].dialog?.filters,
        daterange: {
          ...state.tabs[action.payload.tabID].dialog?.filters?.daterange,
          [action.payload.key]: action.payload.value,
        },
      },
    };
  }
};

export const _setSearchText = (state, action) => {
  state.tabs[action.payload.tabID].dialog = {
    ...state.tabs[action.payload.tabID].dialog,
    filters: {
      ...state.tabs[action.payload.tabID].dialog?.filters,
      searchText: action.payload.value,
    },
  };
};

export const _setSortField = (state, action) => {
  state.tabs[action.payload.tabID].dialog = {
    ...state.tabs[action.payload.tabID].dialog,
    filters: {
      ...state.tabs[action.payload.tabID]?.dialog?.filters,
      sort: action.payload.value,
    },
  };
};
