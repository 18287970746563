import React from "react";
import { Dropdown } from "react-bootstrap";
import { AiOutlineMenu } from "react-icons/ai";
import { useSelector } from "react-redux";
import {
  getCommandParams,
  getFormDesign,
  getObjectState,
  getRecData,
  getRelJobMenu,
  getTabIdx,
} from "../../../../../redux/selectors";
import getWebMenu from "../../../../../services/getWebMenu";
import { useDispatch } from "react-redux";
import {
  setCustomRelJobMenuProperty,
  setLoading,
  setNewTab,
  setRelJobMenu,
} from "../../../../../redux/features/ui/uiSlice";
import { setError } from "../../../../../redux/features/modals/modalsSlice";
import { BsBuildingGear, BsFileEarmarkMedical, BsFillFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { setScreenFromJob } from "../../../../../redux/features/screens/screensSlice";
import { getScreens } from "../../../../../redux/selectors/screens";

const RelJob = ({ tabID }) => {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const screens = useSelector((state) => getScreens(state));
  const data = useSelector((state) => getRecData(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const popUpRedirection = useSelector((state) => state.settings.app?.popUpRedirection);

  const idx = useSelector((state) => getTabIdx(state, tabID));
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const relJobMenu = useSelector((state) => getRelJobMenu(state, tabID));
  const handleClickonRelJob = async (callback) => {
    if (objectState == "view") {
      if (commandParams.rj && !relJobMenu) {
        dispatch(setLoading({ show: true, tabID }));
        const response = await getWebMenu({ menuname: commandParams.rj });
        if (response.success) {
          Object.keys(response.menu).map((key) => {
            if (response.menu[key].command.startsWith("_CheckIn"))
              dispatch(
                setCustomRelJobMenuProperty({
                  idx,
                  tabID,
                  key: "checkIn",
                  value: response.menu[key].command.split(";")[0].split(":")[1],
                })
              );
            if (response.menu[key].command.startsWith("_CheckOut"))
              dispatch(
                setCustomRelJobMenuProperty({
                  idx,
                  tabID,
                  key: "checkOut",
                  value: response.menu[key].command.split(";")[0].split(":")[1],
                })
              );
          });
          dispatch(setRelJobMenu({ value: response.menu, tabID, idx }));
        } else {
          dispatch(setError({ show: true, error: response.error }));
        }
        dispatch(setLoading({ show: false, tabID }));
      }
      callback();
    }
  };

  const handleJob = async (property) => {
    if (property.command.startsWith("_")) {
      handleCustomJob(property);
    } else {
      const job = { ...property };
      const pattern = /^\{([^{}]+)\}(.*)/;
      const match = job.command.match(pattern);
      let customParams = {};
      if (match) {
        const keyValuePairs = match[1].split(",");
        keyValuePairs.forEach((pair) => {
          const [key, value] = pair.split(":");
          if (key.toLowerCase() == "editoptions") {
            const editArray = value.toLowerCase().split(";");
            editArray.forEach((x) => (customParams[x.replaceAll("'", "").replaceAll('"', "")] = true));
          } else {
            customParams[key.toLowerCase()] = value.toLowerCase();
          }
        });
      }

      job.command = match ? match[2] : job.command;

      const EditorArguments = Array.from(job.command.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      EditorArguments.forEach((y) => {
        var splitted = y.split(".");
        var tableName = splitted[0];
        var fieldName = splitted[1];
        var replaceValue;
        if (data[tableName][0].hasOwnProperty(fieldName)) {
          replaceValue = data[tableName][0][fieldName].split("|")[0];
        } else {
          const replaceModel = formDesign.model[tableName].fields.filter((el) => el.name == fieldName)[0];
          if (replaceModel?.editType == "Selector") {
            replaceValue = 0;
          } else {
            replaceValue = "";
          }
        }

        job.command = job.command.replaceAll(`[${y}]`, replaceValue);
      });

      const params = new URLSearchParams(job.command);
      const properties = {};
      for (const [key, value] of params.entries()) {
        properties[key] = value;
      }
      job.commandParams = { ...properties, ...customParams };
      job.screenState = { present: undefined, past: undefined };
      job.objectState = "view";
      job.browseExpandable = job.cmd === "EditMaster" ? true : undefined;
      job.isWindow = popUpRedirection == 1;

      const value = {
        ...job,
        idx: `job_${idx}_${job.idx}`,
      };
      dispatch(
        setScreenFromJob({
          key: `job_${idx}_${job.idx}`,
          value,
        })
      );
      dispatch(setNewTab(value));
    }
  };

  const handleCustomJob = async (property) => {
    const commandAray = property.command.split(";");
    const field = property.command.split(":")[1];
  };

  const RelJobDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      className="d-flex align-items-center justify-content-center user-select-none text-light"
      onClick={(e) => {
        e.preventDefault();
        handleClickonRelJob(() => onClick(e));
      }}
    >
      {children}
    </a>
  ));

  return (
    <Dropdown className="d-flex" align="start">
      <Dropdown.Toggle as={RelJobDropDown}>
        <div
          className="d-flex align-items-center justify-content-start"
          style={{
            userSelect: "none",
            cursor: objectState == "view" ? "pointer" : "default",
            opacity: objectState == "view" ? 1 : 0.5,
          }}
        >
          <AiOutlineMenu size="1.2rem" className="me-1" />
        </div>
      </Dropdown.Toggle>
      {relJobMenu && (
        <Dropdown.Menu
          className="shadow"
          style={{ maxWidth: "300px", maxHeight: "calc(100vh - 400px)", overflowY: "auto", overflow: "hidden" }}
        >
          <div className="d-flex flex-column">
            <span className="s1-rel-job-menu-title">Σχετικές Εργασίες</span>
            {Object.keys(relJobMenu).map((key) => (
              <Dropdown.Item
                id={`id-mn${key}`}
                key={key}
                className="s1-rel-job-menu-item"
                onClick={() => handleJob(relJobMenu[key])}
              >
                <div className="d-flex">
                  {{
                    EditMaster: <BsFileEarmarkMedical color="var(--bs-orange)" />,
                    Report: <BsFillFileEarmarkSpreadsheetFill color="rgb(19, 185, 148)" />,
                    Dialog: <BsBuildingGear color="var(--bs-danger)" />,
                  }[relJobMenu[key].cmd] ?? "default value"}
                </div>
                <span className="text-truncate me-2">{relJobMenu[key]?.text}</span>
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default RelJob;
