import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../../../redux/features/modals/modalsSlice";
import { setDropBox } from "../../../../redux/features/settings/settingsSlice";

const Dropbox = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.cloudStorage.dropbox);

  const handleDbxConnect = () => {
    dispatch(setLoading(true));
    const config = {
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
      clientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET,
    };
    var Dropbox = require("dropbox").Dropbox;
    var dbx = new Dropbox(config);
    dbx.auth
      .getAuthenticationUrl(process.env.REACT_APP_DROPBOX_REDIRECT_URL, null, "code", "offline", null, "none", false)
      .then((authUrl) => {
        window.location.href = authUrl;
      })
      .catch((er) => {
        dispatch(setLoading(false));
        dispatch(setError({ show: true, message: er.message }));
        console.log(er);
      });
  };
  return (
    <>
      <div className="d-flex align-items-center gap-4">
        <span style={{ fontSize: "1.2rem" }}>{`Dropbox${settings ? ` - ${settings?.account?.email}` : ""}`}</span>
        {!settings ? (
          <Button onClick={handleDbxConnect}>Σύνδεση</Button>
        ) : (
          <Button variant="danger" onClick={() => dispatch(setDropBox(undefined))}>
            Αποσύνδεση
          </Button>
        )}
      </div>
      <hr className="m-1" />
    </>
  );
};

export default Dropbox;
