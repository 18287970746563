import React from "react";
import { AiFillCaretRight } from "react-icons/ai";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux/es";
import { getHiddenColumns, getTableColumns } from "../../../../../redux/selectors";
import { setColumnVisibility } from "../../../../../redux/features/ui/uiSlice";

const Columns = ({ menuRef }) => {
  const dispatch = useDispatch();
  const thead = useSelector((state) => state.ui.contextMenu.thead);

  const clickRef = React.useRef(null);
  const columnRef = React.useRef(null);

  const tableColumns = useSelector((state) => getTableColumns(state, thead.tabID));
  const hiddenColumns = useSelector((state) => getHiddenColumns(state, thead.tabID));

  const [columnsProps, setColumnsProps] = React.useState({});

  const handleClickOnColumn = (column, accessor, value) => {
    dispatch(setColumnVisibility({ tabID: thead.tabID, column, accessor, value }));
  };

  React.useEffect(() => {
    columnsProps.show && setColumnsProps({ ...columnsProps, width: columnRef.current.offsetWidth });
  }, [columnsProps.show]);

  return (
    <>
      <div
        ref={clickRef}
        style={{ cursor: "pointer", textDecoration: "none", color: "var(--bs-dark)" }}
        className="tab-list-context-menu-action d-flex align-items-center justify-content-between user-select-none"
        onClick={(e) => {
          const { right, left } = menuRef.current.getBoundingClientRect();
          setColumnsProps({
            show: !columnsProps.show,
            right,
            left,
            top: clickRef.current.getBoundingClientRect().top,
            width: columnsProps.width,
          });
        }}
      >
        <div className="d-flex align-items-center">
          <HiOutlineViewGridAdd size="1rem" className="me-1" />
          <span style={{ fontSize: "1rem" }}>Στήλες</span>
        </div>
        <div style={{ fontSize: "0.7rem" }}>
          <AiFillCaretRight />
        </div>
      </div>
      {columnsProps.show && (
        <div
          ref={columnRef}
          className="tab-list-context-menu select-none"
          style={{
            top: columnsProps.top,
            left:
              columnsProps.right + columnsProps.width > window.innerWidth
                ? clickRef.current.getBoundingClientRect().left - columnsProps.width
                : columnsProps.right,
          }}
        >
          {tableColumns &&
            tableColumns.map(
              (item, index) =>
                item.canReveal && (
                  <div
                    className="tab-list-context-menu-action d-flex"
                    role="button"
                    key={index}
                    onClick={() =>
                      (hiddenColumns.indexOf(item.accessor) > -1 || hiddenColumns.length < tableColumns.length - 1) &&
                      handleClickOnColumn(index, item.accessor, !item.hidden)
                    }
                  >
                    <div className="me-2">
                      <input
                        type="checkbox"
                        checked={hiddenColumns.indexOf(item.accessor) < 0}
                        readOnly={true}
                        disabled={
                          hiddenColumns.indexOf(item.accessor) < 0 && hiddenColumns.length == tableColumns.length - 1
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div>{item.Header}</div>
                  </div>
                )
            )}
        </div>
      )}
    </>
  );
};

export default Columns;
