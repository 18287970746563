import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import DropBox from "./buttons/DropBox";
import GoogleDrive from "./buttons/GoogleDrive";

const NewButton = ({ tabID, element }) => {
  const buttonref = React.useRef(null);
  const menuRef = React.useRef(null);
  const [menu, setMenu] = React.useState({ show: false, x: undefined, y: undefined });

  React.useEffect(() => {
    if (menu.show) {
      const clickListener = (e) => {
        if (!menuRef.current.contains(e.target)) {
          setMenu({ show: false });
        }
      };
      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [menu.show]);

  const DropOptions = () => (
    <div
      ref={menuRef}
      className="tab-list-context-menu select-none text-dark"
      style={{
        top: menu.y,
        left: menu.x,
      }}
    >
      <DropBox tabID={tabID} element={element} setMenu={setMenu} />
      <GoogleDrive tabID={tabID} element={element} setMenu={setMenu} />
      {/* <OneDrive tabID={tabID} element={element} setMenu={setMenu} /> */}
    </div>
  );

  return (
    <div
      role="button"
      ref={buttonref}
      className="d-flex justify-content-center align-items-center"
      onClick={(e) => {
        e.stopPropagation();
        setMenu({
          show: true,
          x: buttonref.current.getBoundingClientRect().right,
          y: buttonref.current.getBoundingClientRect().bottom,
        });
      }}
    >
      <AiOutlinePlus
        onMouseEnter={(e) => {
          e.stopPropagation();
          e.target.style.color = "rgb(64, 180, 252)";
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          e.target.style.color = "";
        }}
      />
      {menu.show && <DropOptions />}
    </div>
  );
};

export default NewButton;
