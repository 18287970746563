import axios from "axios";
import store from "../redux/store";
import { getClientID, getCommandByIndex, getSN, getURL } from "../redux/selectors";

const setData = async ({ key, data, locateinfo, tabID, defaultObject }) => {
  const state = store.getState();
  const url = getURL(state);
  const clientID = getClientID(state);
  const serialnumber = getSN(state);
  const command = getCommandByIndex(state, tabID);
  const object = `${serialnumber}/object=${command}`;

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "setData",
        clientID,
        object: defaultObject ?? object,
        key,
        data,
        locateinfo,
        version: 2,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default setData;
