import React from "react";
import { Button } from "react-bootstrap";
import { IoMdInformationCircle } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { getGadget } from "../../../redux/selectors";
import { getScreens } from "../../../redux/selectors/screens";
import { setScreenFromJob } from "../../../redux/features/screens/screensSlice";
import { setNewTab } from "../../../redux/features/ui/uiSlice";

const GadgetNoData = ({ id }) => {
  const dispatch = useDispatch();
  const gadget = useSelector((state) => getGadget(state, id));
  const screens = useSelector((state) => getScreens(state));
  const handleAddNew = () => {
    const object = gadget.data.OBJECT;
    const list = gadget.data.LIST;
    const form = gadget.data.FORM;
    const browserInfo = gadget.browserInfo;
    const tableColumns = gadget.tableColumns;
    const tableData = gadget.tableData;
    const hiddenColumns = gadget.hiddenColumns;
    const command = `${object}&list=${list}${form ? `&form=${form}` : ""}&AppId=${
      process.env.REACT_APP_SOFTONE_APPID
    }&w=96&ae=2`;
    const commandParams = { list, form, AppId: process.env.REACT_APP_SOFTONE_APPID, ae: 2 };
    const value = {
      cmd: "EditMaster",
      text: gadget?.data?.title ?? gadget.name,
      idx: `gadget_${id}`,
      command,
      commandParams,
      browserInfo,
      tableColumns,
      tableData,
      hiddenColumns,
      screenState: {},
      objectState: "view",
    };
    dispatch(
      setScreenFromJob({
        key: `gadget_${id}`,
        value,
      })
    );
    dispatch(setNewTab(value));
  };
  return (
    <div className="d-flex justify-content-center flex-column align-items-center" style={{ flexGrow: 1 }}>
      <IoMdInformationCircle size="128px" color="var(--bs-secondary)" />
      <span style={{ fontSize: "1.2rem", color: "#4A4A4A" }}>Δεν υπάρχουν εγγραφές</span>
      <Button variant="secondary" className="mt-2" onClick={handleAddNew}>
        Νέα εγγραφή
      </Button>
    </div>
  );
};

export default GadgetNoData;
