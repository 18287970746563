import React from "react";
import ArrowsBack from "./footer/ArrowsBack";
import Pages from "./footer/Pages";
import ArrowsNext from "./footer/ArrowsNext";
import { Container } from "react-bootstrap";

const TFoot = ({
  tabID,
  pageIndex,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
}) => {
  return (
    <div className="browser-table-tfoot">
      <Container
        fluid
        style={{ backgroundColor: "var(--bs-gray-dark)", gap: "1rem", height: 40 }}
        className="text-light d-flex align-items-center py-2"
      >
        <ArrowsBack tabID={tabID} canPreviousPage={canPreviousPage} gotoPage={gotoPage} previousPage={previousPage} />
        <div className="vr" />
        <Pages tabID={tabID} pageIndex={pageIndex} pageOptions={pageOptions} gotoPage={gotoPage} />
        <div className="vr" />
        <ArrowsNext
          tabID={tabID}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          pageCount={pageCount}
          nextPage={nextPage}
        />
      </Container>
    </div>
  );
};

export default TFoot;
