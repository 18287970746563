import React from "react";
import { useSelector } from "react-redux";
import { getDataCaption, getObjectState } from "../../../../redux/selectors";

const FormCaption = ({ tabID }) => {
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const dataCaption = useSelector((state) => getDataCaption(state, tabID));
  return (
    <div className="caption mb-2 d-flex align-items-center" style={{ height: "1.5rem", minHeight: "1.5rem" }}>
      <span style={{ fontSize: "1.2rem", fontWeight: 500 }}>
        {objectState == "new" ? "Νέα εγγραφή" : dataCaption ?? ""}
      </span>
    </div>
  );
};

export default FormCaption;
