import React from "react";
import { Button, Modal } from "react-bootstrap";

const ModalDelete = ({ show, setShow, setResponse }) => {
  const handleAccept = () => {
    setShow(false);
    setResponse(true);
  };

  const handleDecline = () => {
    setShow(false);
  };

  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={show}
      animation={false}
      centered
      onHide={handleDecline}
      backdrop="static"
    >
      <Modal.Header className="p-2 ps-3" closeButton style={{ userSelect: "none" }}>
        Ερώτηση
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-start align-items-center" style={{ userSelect: "none" }}>
        Προκειται να διαγράψετε οριστικά μια εγγραφή! <br />
        Θέλετε να συνέχισετε;
      </Modal.Body>
      <Modal.Footer className="d-flex p-0">
        <Button onClick={handleAccept}>Ναι</Button>
        <Button onClick={handleDecline}>Όχι</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;
