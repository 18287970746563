import React from "react";
import { Container } from "react-bootstrap";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getGadgetCategory, getGadgetCollapsed, getGadgetConfig, getGadgetData } from "../../../redux/selectors";
import { collapse, toogleVisibility, setFullWindow } from "../../../redux/features/portal/portalSlice";
import { HiX } from "react-icons/hi";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";
import Refresh from "./category5/gadget head/Refresh";
import Print from "./category5/gadget head/Print";
import { BsWindowFullscreen } from "react-icons/bs";

const Head = ({ id, dndProps }) => {
  const dispatch = useDispatch();
  const collapsed = useSelector((state) => getGadgetCollapsed(state, id));
  const category = useSelector((state) => getGadgetCategory(state, id));
  const data = useSelector((state) => getGadgetData(state, id));
  const config = useSelector((state) => getGadgetConfig(state, id));
  const fullScreen = useSelector((state) => state.portal.fullWindow);

  const handleSetFullScreen = (id) => {
    const portal = document.getElementById("d1-crm-portal");
    dispatch(setFullWindow({ id, y: portal.scrollTop }));
  };
  return (
    <div
      {...dndProps}
      style={{
        flexShrink: 0,
        color: { 5: "var(--bs-light)" }[category] ?? "var(--bs-dark)",
        backgroundColor: { 5: "#13b994" }[category] ?? "var(--bs-gray-400)",
        height: "56px",
      }}
    >
      <Container fluid className="py-3 d-flex align-items-center justify-content-between">
        <div className="d-flex gap-3">
          <div
            role="button"
            className="d-flex justify-content-center align-items-center"
            onClick={() => dispatch(collapse({ value: !collapsed, id }))}
          >
            {collapsed ? <FaChevronCircleDown /> : <FaChevronCircleUp />}
          </div>
          <div className="d-flex justify-content-center align-items-center gap-1">
            <BsWindowFullscreen />
            <span style={{ userSelect: "none" }}>{data?.title ?? config?.title}</span>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-2">
          {(!collapsed || fullScreen == id) && <Refresh id={id} />}
          {(!collapsed || fullScreen == id) && <Print id={id} />}
          {fullScreen == id ? (
            <div
              role="button"
              className="d-flex justify-content-center align-items-center"
              onClick={() => dispatch(setFullWindow({ id: undefined }))}
            >
              <BiExitFullscreen />
            </div>
          ) : (
            <div
              role="button"
              className="d-flex justify-content-center align-items-center"
              onClick={() => handleSetFullScreen(id)}
            >
              <BiFullscreen />
            </div>
          )}
          <div
            role="button"
            onClick={() => dispatch(toogleVisibility(id))}
            className="d-flex justify-content-center align-items-center"
          >
            <HiX />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Head;
