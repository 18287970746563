import { createSlice } from "@reduxjs/toolkit";
import { clear } from "../session/sessionSlice";

const initialState = {};

const rootsSlice = createSlice({
  name: "roots",
  initialState,
  reducers: {
    set: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clear, () => {
      return initialState;
    });
  },
});

export const { set } = rootsSlice.actions;

export default rootsSlice.reducer;
