import React from "react";
import SideBar from "../../bars/SideBar";
import TabsBar from "../../bars/TabsBar";

const MainPanel = ({ webMenuData }) => {
  const [currentMenus, setCurrentMenus] = React.useState(webMenuData);
  const [previousStack, setPreviousStack] = React.useState([]);
  const [currrentTitle, setCurrentTitle] = React.useState("");
  React.useEffect(() => {
    setCurrentMenus(webMenuData);
    setPreviousStack([]);
  }, []);

  return (
    <div className="d-flex overflow-hidden" style={{ flex: 1 }}>
      <SideBar
        webMenuData={webMenuData}
        currentMenus={currentMenus}
        setCurrentMenus={setCurrentMenus}
        previousStack={previousStack}
        setPreviousStack={setPreviousStack}
        currrentTitle={currrentTitle}
        setCurrentTitle={setCurrentTitle}
      />
      <div
        className="bg-light d-flex flex-column"
        style={{
          flex: 1,
          overflowX: "hidden",
        }}
      >
        <TabsBar />
      </div>
    </div>
  );
};

export default MainPanel;
