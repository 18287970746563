export const _setNewTab = (state, action) => {
  const obj = { ...action.payload, id: Date.now(), flexLayout: true, resetColumnWidths: 0 };
  state.tabs[obj.id] = obj;
  state.order.push({
    text: obj.text,
    cmd: obj.cmd,
    loading: obj.loading,
    id: obj.id,
    isWindow: obj.isWindow,
  });
  if (obj.isWindow) state.windowID = obj.id;
  else state.tabID = obj.id;
};

export const _setTab = (state, action) => {
  state.tabID = action.payload;
  state.contextMenu.show = false;
};

export const _updateTabOrder = (state, action) => {
  const updatedComponents = state.order;
  const [removedComponent] = updatedComponents.splice(action.payload.source, 1);
  updatedComponents.splice(action.payload.destination, 0, removedComponent);
  state.order = updatedComponents;
};

export const _setLoading = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].loading = action.payload.show;
    const orderIndex = state.order.findIndex((item) => item.id == action.payload.tabID);
    state.order[orderIndex].loading = action.payload.show;
  }
};

export const _killTab = (state, action) => {
  const isWindow = state.tabs[action.payload].isWindow;
  const activeTabID = state.tabID;
  const tabID2Delete = action.payload;
  const activeOrderIndex = state.order.findIndex((item) => item.id == activeTabID);
  const orderIndex2Delete = state.order.findIndex((item) => item.id == tabID2Delete);
  state.order.splice(orderIndex2Delete, 1);
  delete state.tabs[tabID2Delete];
  if (state.order.length > 0) {
    if (activeOrderIndex >= orderIndex2Delete) {
      if (activeOrderIndex != 0) state.tabID = state.order[activeOrderIndex - 1].id;
      else state.tabID = state.order[0].id;
    }
  } else state.tabID = -1;

  if (isWindow) state.windowID = undefined;
  state.contextMenu.show = false;
};

export const _killAllTabs = (state) => {
  state.tabs = {};
  state.order = [];
  state.tabID = -1;
  state.orderIndex = -1;
};

export const _killAllTabsExceptOne = (state, action) => {
  const tabID = action.payload;
  const orderIndex = state.order.findIndex((item) => item.id == tabID);
  state.order.splice(0, orderIndex);
  state.order.splice(1);
  state.tabs = { [tabID]: state.tabs[tabID] };
  state.tabID = tabID;
};

export const _showTabContextMenu = (state, action) => {
  state.contextMenu.tab = action.payload;
};
