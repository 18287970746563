import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDialog, getScreenState, getTabIdx } from "../../../redux/selectors";
import S1StringsMulti from "../s1 inputs/S1StringsMulti";
import S1MultiSelect from "../s1 inputs/S1MultiSelect";
import S1DateRange from "../s1 inputs/S1DateRange";
import S1DateRangeDropDown from "../s1 inputs/S1DateRangeDropDown";
import S1CheckBox from "../s1 inputs/S1CheckBox";
import S1Selector from "../s1 inputs/S1Selector";
import S1TextField from "../s1 inputs/S1TextField";
import S1CalendarDate from "../s1 inputs/S1CalendarDate";
import S1Strings from "../s1 inputs/S1Strings";
import S1IntField from "../s1 inputs/S1IntField";
import S1NumberField from "../s1 inputs/S1NumberField";
import { setDialog, setLoading } from "../../../redux/features/ui/uiSlice";
import serviceGetDialog from "../../../services/getDialog";

const Dialog = (props) => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => getDialog(state, props.tabID));
  const screenState = useSelector((state) => getScreenState(state, props.tabID));
  const idx = useSelector((state) => getTabIdx(state, props.tabID));

  const initDialog = async () => {
    dispatch(setLoading({ show: true, tabID: props.tabID }));
    const dialog = await serviceGetDialog(props.tabID);
    dispatch(setDialog({ value: dialog, idx, tabID: props.tabID }));
    dispatch(setLoading({ show: false, tabID: props.tabID }));
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      if (!dialog?.form) {
        initDialog();
      } else {
        dispatch(setLoading({ show: false, tabID: props.tabID }));
      }
    }
  }, [screenState]);

  const Recursive = (data, parentIndex) => {
    return data.map((element) => {
      switch (element.xtype) {
        case "s1cont":
          return (
            <React.Fragment key={element.index}>
              <div
                className={`s1cont${element.items.every((x) => x.xtype == "s1daterange") ? " daterange gap-2" : ""}`}
              >
                {Recursive(element.items, element.index)}
                {element.items.every((x) => x.xtype == "s1daterange") && (
                  <S1DateRangeDropDown screenState="dialog" parentIndex={element.index} tabID={props.tabID} />
                )}
              </div>
            </React.Fragment>
          );
        case "s1daterange":
          return (
            <S1DateRange
              screenState="dialog"
              element={element}
              tabID={props.tabID}
              key={element.index}
              parentIndex={parentIndex}
            />
          );
        case "s1multiselect":
          return <S1MultiSelect screenState="dialog" element={element} tabID={props.tabID} key={element.index} />;
        case "s1stringsmulti":
          return <S1StringsMulti screenState="dialog" element={element} tabID={props.tabID} key={element.index} />;
        case "s1selector":
          return <S1Selector screenState="dialog" element={element} tabID={props.tabID} key={element.index} />;
        case "s1textfield":
          return <S1TextField screenState="dialog" element={element} tabID={props.tabID} key={element.index} />;
        case "s1checkbox":
          return <S1CheckBox screenState="dialog" element={element} tabID={props.tabID} key={element.index} />;
        case "s1calendardate":
          return <S1CalendarDate screenState="dialog" element={element} tabID={props.tabID} key={element.index} />;
        case "s1strings":
          return <S1Strings screenState="dialog" element={element} tabID={props.tabID} key={element.index} />;
        case "s1intfield":
          return <S1IntField screenState="dialog" element={element} tabID={props.tabID} key={element.index} />;
        case "s1numberfield":
          return <S1NumberField screenState="dialog" element={element} tabID={props.tabID} key={element.index} />;
        case "s1spacer":
          return (
            <div
              className="s1spacer"
              key={element.index}
              style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
            />
          );
        default:
          return (
            <div
              className="bg-danger"
              key={element.index}
              style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
            >
              {element.xtype}
            </div>
          );
      }
    });
  };

  return (
    <div style={{ ...props.style }} className="s1-object-dialog">
      {dialog?.form && Recursive(dialog.form)}
    </div>
  );
};

export default Dialog;
