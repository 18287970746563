import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import CrmDropDownPrinter from "../../../../ui/custom inputs/CrmDropDownPrinter";
import CrmDropDownTemplates from "../../../../ui/custom inputs/CrmDropDownTemplates";
import { getCommand } from "../../../../../redux/selectors";
import { setPrinterOptions } from "../../../../../redux/features/settings/settingsSlice";

const ModalPrintRecord = ({ show, setShow, accept, tabID }) => {
  const dispatch = useDispatch();
  const outputFormats = useSelector((state) => state.systemParams?.outputformats?.form);
  const [printer, setPrinter] = React.useState("");
  const [template, setTemplate] = React.useState(["", ""]);
  const command = useSelector((state) => getCommand(state));
  const object = command && command.substring(0, command.indexOf("&"));

  const settings = useSelector((state) => state.settings);

  React.useEffect(() => {
    if (show) {
      if (settings?.printerOptions?.[object]) {
        setPrinter(settings?.printerOptions?.[object]?.printer);
        setTemplate(settings?.printerOptions?.[object]?.template);
      } else {
        setPrinter("");
        setTemplate(["", ""]);
      }
    }
  }, [show]);

  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={show}
      backdrop="static"
      animation={false}
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Header closeButton className="p-2 ps-3">
        Εκτύπωση Φόρμας
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
        <CrmDropDownPrinter
          label="Εκτυπωτής"
          type="text"
          name="printer"
          inputMode="none"
          required
          value={printer}
          data={{ ...outputFormats }}
          function={(value) => setPrinter(value)}
        />
        <CrmDropDownTemplates
          label="Form"
          type="text"
          name="template"
          inputMode="none"
          required
          value={template[1]}
          tabID={tabID}
          function={(value) => setTemplate(value)}
        />
        <div className="d-flex justify-content-center align-items-center mt-3">
          <Button
            variant="secondary"
            onClick={() => dispatch(setPrinterOptions({ key: object, value: { printer, template } }))}
          >
            Αποθήκευση προτύπου
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center p-0">
        <Button
          onClick={() => {
            console.log(template);
            accept(printer, template[0]);
            setShow(false);
          }}
        >
          Εκτύπωση
        </Button>
        <Button onClick={() => setShow(false)}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPrintRecord;
