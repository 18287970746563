import React from "react";
import { useSelector } from "react-redux";
import { getHiddenColumns, getTableColumns, getTableData } from "../../../redux/selectors";
import { useBlockLayout, useFilters, usePagination, useResizeColumns, useTable } from "react-table";
import THead from "./table/THead";
import TBody from "./table/TBody";
import TFoot from "../edit master/browser/table/TFoot";

const Browser = ({ tabID }) => {
  const HeadRef = React.useRef(null);
  const BodyRef = React.useRef(null);

  const settings = useSelector((state) => state.settings.app);

  const tableColumns = useSelector((state) => getTableColumns(state, tabID));
  const tableData = useSelector((state) => getTableData(state, tabID));

  const data = React.useMemo(() => tableData ?? [], [tableData]);
  const columns = React.useMemo(() => tableColumns ?? [], [tableColumns]);

  const initialState = {
    pageIndex: 0,
    pageSize: parseInt(settings.browserPageSize),
    hiddenColumns: useSelector((state) => getHiddenColumns(state, tabID)) ?? [],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state: { pageIndex },
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    resetResizing,
  } = useTable(
    {
      columns,
      data,
      initialState,
      // defaultColumn: { columnWidth: !flexLayout ? "auto" : undefined },
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    usePagination
  );

  return (
    <>
      <THead ref={HeadRef} headerGroups={headerGroups} tabID={tabID} rows={rows?.[0]} />
      <TBody
        ref={BodyRef}
        HeadRef={HeadRef}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
        rows={rows}
        tabID={tabID}
        page={page}
        footerGroups={footerGroups}
      />
      <TFoot
        pageIndex={pageIndex}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
      />
    </>
  );
};

export default Browser;
