export const _setCalendarDates = (state, action) => {
  state.tabs[action.payload.tabID].calendar = {
    ...state.tabs[action.payload.tabID]?.calendar,
    showInsert: action.payload.show,
    selectedDates: {
      ...state.tabs[action.payload.tabID]?.calendar?.selectedDates,
      start: action.payload.start,
      end: action.payload.end,
    },
  };
};
