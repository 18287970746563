import React from "react";
import { HiPrinter } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../../redux/features/ui/uiSlice";
import { getOutputFormats, getReportInfo, getReportData, getSN } from "../../../../../redux/selectors";
import print from "../../../../../services/print";
import { Dropdown } from "react-bootstrap";
import { setError } from "../../../../../redux/features/modals/modalsSlice";
import ModalDownload from "../../edit master/browser/ModalDownload";

const PrintReport = ({ tabID }) => {
  const dispatch = useDispatch();
  const serialnumber = useSelector((state) => getSN(state));
  const outputFormats = useSelector((state) => getOutputFormats(state)?.list);

  const [printURL, setPrintURL] = React.useState(undefined);
  const [fileName, setFileName] = React.useState("");
  const [format, setFormat] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  const reportInfo = useSelector((state) => getReportInfo(state, tabID));
  const reportData = useSelector((state) => getReportData(state, tabID));

  const handlePrintBrowser = async (format) => {
    dispatch(setLoading({ show: true, tabID }));
    const printDoc = await print({ reqID: reportInfo.reqID, format });
    if (printDoc.success) {
      setPrintURL(`https://${serialnumber}.oncloud.gr/s1services/?filename=${printDoc.filename}`);
      setFileName(printDoc.filename.replace(/[<>:"/\\|?*\x00-\x1F]/g, "_"));
      setFormat(format);
      setShowModal(true);
    } else {
      dispatch(setError({ show: true, message: printDoc.error }));
      console.log("error while printing:", printDoc);
    }
    dispatch(setLoading({ show: false, tabID }));
  };

  const PrintDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      className="d-flex align-items-center justify-content-center user-select-none text-light"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  return (
    <Dropdown className="d-flex" align="start">
      <Dropdown.Toggle as={PrintDropDown}>
        <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer" }}>
          <HiPrinter size="1.2rem" className="me-1" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="shadow">
        {Object.keys(outputFormats).map((key) => {
          return (
            key != "XPS" && (
              <Dropdown.Item key={key} onClick={() => handlePrintBrowser(key)}>
                {{
                  "Ascii file": "Ascii",
                  "PDF file": "PDF",
                  "Html image file": "Html",
                  "Spreadsheet file": "Excel",
                  "Document file": "Word",
                }[outputFormats[key]] ?? outputFormats[key]}
              </Dropdown.Item>
            )
          );
        })}
      </Dropdown.Menu>
      <ModalDownload
        show={showModal}
        setShow={setShowModal}
        url={printURL}
        fileName={fileName}
        format={format}
        tabID={tabID}
      />
    </Dropdown>
  );
};

export default PrintReport;
