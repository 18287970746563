import React from "react";
import { FaLayerGroup } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux/es";
import { getgroupToogle } from "../../../../../redux/selectors";
import { groupToogle } from "../../../../../redux/features/ui/uiSlice";

const Groupings = ({ tabID }) => {
  const dispatch = useDispatch();
  const toogle = useSelector((state) => getgroupToogle(state, tabID));
  return (
    <div
      className="d-flex align-items-center justify-content-start"
      style={{ cursor: "pointer" }}
      onClick={() => dispatch(groupToogle({ tabID, value: !toogle }))}
    >
      <FaLayerGroup size="1rem" />
    </div>
  );
};

export default Groupings;
