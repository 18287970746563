import React from "react";
import { HiPrinter } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../../redux/features/ui/uiSlice";
import { getBrowserInfo, getOutputFormats, getSN } from "../../../../../redux/selectors";
import print from "../../../../../services/print";
import { Dropdown } from "react-bootstrap";
import ModalDownload from "./ModalDownload";
import { setError } from "../../../../../redux/features/modals/modalsSlice";

const PrintBrowser = ({ tabID }) => {
  const dispatch = useDispatch();
  const serialnumber = useSelector((state) => getSN(state));
  const outputFormats = useSelector((state) => getOutputFormats(state)?.list);

  const [printURL, setPrintURL] = React.useState(undefined);
  const [fileName, setFileName] = React.useState("");
  const [format, setFormat] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));

  const canCommit = browserInfo?.success && browserInfo?.totalcount > 0;

  const handlePrintBrowser = async (format) => {
    dispatch(setLoading({ show: true, tabID }));
    const printDoc = await print({ reqID: browserInfo.reqID, format, tabID });
    if (printDoc.success) {
      setPrintURL(`https://${serialnumber}.oncloud.gr/s1services/?filename=${printDoc.filename}`);
      setFileName(printDoc.filename.replace(/[<>:"/\\|?*\x00-\x1F]/g, "_"));
      setFormat(format);
      setShowModal(true);
    } else {
      dispatch(setError({ show: false, message: printDoc.error }));
    }
    dispatch(setLoading({ show: false, tabID }));
  };

  const PrintDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      className="d-flex align-items-center justify-content-center user-select-none text-light"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  return (
    <Dropdown className="d-flex" align="start">
      <Dropdown.Toggle as={PrintDropDown}>
        <div
          className="d-flex align-items-center justify-content-start"
          style={{
            userSelect: "none",
            cursor: canCommit ? "pointer" : "default",
            opacity: canCommit ? 1 : 0.5,
          }}
        >
          <HiPrinter size="1.2rem" className="me-1" />
        </div>
      </Dropdown.Toggle>
      {canCommit && (
        <Dropdown.Menu className="shadow">
          {Object.keys(outputFormats).map((key) => {
            return (
              key != "XPS" && (
                <Dropdown.Item key={key} onClick={() => handlePrintBrowser(key)}>
                  {{
                    "Ascii file": "Ascii",
                    "PDF file": "PDF",
                    "Html image file": "Html",
                    "Spreadsheet file": "Excel",
                    "Document file": "Word",
                  }[outputFormats[key]] ?? outputFormats[key]}
                </Dropdown.Item>
              )
            );
          })}
        </Dropdown.Menu>
      )}
      <ModalDownload
        show={showModal}
        setShow={setShowModal}
        url={printURL}
        fileName={fileName}
        format={format}
        tabID={tabID}
      />
    </Dropdown>
  );
};

export default PrintBrowser;
