import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const comesFromURL = createSlice({
  name: "comesFromURL",
  initialState,
  reducers: {
    set: (state, action) => {
      return {
        ...action.payload,
      };
    },
  },
});

export const { set } = comesFromURL.actions;

export default comesFromURL.reducer;
