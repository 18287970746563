import React from "react";
import { FiTrash2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import { getSelectedRow } from "../../../../../redux/selectors";
import ModalDelete from "../modals/ModalDelete";
import deleteRecord from "../../../../functions/deleteRecord";

const DeleteRecord = ({ tabID }) => {
  const selectedRow = useSelector((state) => getSelectedRow(state, tabID));
  const [showDelete, setShowDelete] = React.useState(false);
  const [response, setResponse] = React.useState(false);

  React.useEffect(() => {
    if (response) {
      deleteRecord(tabID);
      setResponse(false);
    }
  }, [response]);

  return (
    <React.Fragment>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => selectedRow > -1 && setShowDelete(true)}
        style={{
          userSelect: "none",
          cursor: selectedRow > -1 ? "pointer" : "default",
          opacity: selectedRow > -1 ? 1 : 0.5,
        }}
      >
        <FiTrash2 size="1rem" className="me-1" />
        Διαγραφη
      </div>
      <ModalDelete show={showDelete} setShow={setShowDelete} setResponse={setResponse} />
    </React.Fragment>
  );
};

export default DeleteRecord;
