import React from "react";
import { Dropdown } from "react-bootstrap";
import { AiOutlineSetting } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setSettingsModal } from "../../../redux/features/modals/modalsSlice";

const Settings = () => {
  const dispatch = useDispatch();
  return (
    <Dropdown.Item href="#" onClick={() => dispatch(setSettingsModal(true))}>
      <div className="d-flex justify-content-start align-items-center gap-1">
        <AiOutlineSetting />
        Ρυθμίσεις
      </div>
    </Dropdown.Item>
  );
};

export default Settings;
