import React from "react";
import { Container } from "react-bootstrap";
import { HiSearch } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setBrowserInfo, setLoading, setScreenState } from "../../../../redux/features/ui/uiSlice";
import serviceGetBrowserInfo from "../../../../services/getBrowserInfo";
import PrintBrowser from "./browser/PrintBrowser";
import DeleteRecord from "./browser/DeleteRecord";
import { getBrowserInfo, getBrowserView, getCommandParams, getCurrentTab } from "../../../../redux/selectors";
import NavigationArrow from "./browser/NavigationArrow";
import Search from "./browser/Search";
import Calendar from "./browser/Calendar";
import New from "./browser/New";
import NewOnCalendar from "./browser/NewOnCalendar";
import Groupings from "./browser/Groupings";
const TopBarOnBrowser = ({ tabID }) => {
  const dispatch = useDispatch();
  const canCalendar = useSelector((state) => getCurrentTab(state, tabID)?.canCalendar);
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const browserView = useSelector((state) => getBrowserView(state, tabID));

  const handleReload = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const browserInfo = await serviceGetBrowserInfo(tabID);
    dispatch(setBrowserInfo({ value: browserInfo, tabID }));
    dispatch(setLoading({ show: false, tabID }));
  };

  const handleGoToDialog = () => {
    dispatch(setScreenState({ value: "dialog", tabID }));
  };

  return (
    <Container fluid className="object-top-bar">
      <div
        onClick={handleReload}
        className="d-flex align-items-center justify-content-start"
        style={{ cursor: "pointer" }}
      >
        <HiSearch size="1.1rem" className="me-1" />
        Λίστα
      </div>
      <div
        onClick={handleGoToDialog}
        className="d-flex align-items-center justify-content-start"
        style={{ cursor: "pointer" }}
      >
        <FaFilter size="0.9rem" />
      </div>

      {{
        calendar: <NewOnCalendar tabID={tabID} />,
      }[browserView] ?? <New tabID={tabID} />}
      {browserInfo?.browserOnly != "true" && !commandParams.nodelete && <DeleteRecord tabID={tabID} />}
      <NavigationArrow tabID={tabID} />
      {!commandParams.noprintbrowser && <PrintBrowser tabID={tabID} />}
      {(browserInfo?.gfilters?.fields ?? []).length > 0 && <Groupings tabID={tabID} />}
      {canCalendar && <Calendar tabID={tabID} />}
      <Search tabID={tabID} />
    </Container>
  );
};

export default TopBarOnBrowser;
