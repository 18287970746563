import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../redux/features/modals/modalsSlice";

const ModalMessage = () => {
  const dispatch = useDispatch();
  const buttonRef = React.useRef(null);
  const message = useSelector((state) => state.modals.message);

  React.useEffect(() => {
    if (message.show) {
      buttonRef.current.focus();
    }
  }, [message?.show]);
  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={message.show}
      animation={false}
      onHide={() => dispatch(setMessage({ show: false }))}
      centered
    >
      <Modal.Header className="p-2 ps-3" closeButton style={{ userSelect: "none" }}>
        Μήνυμα
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-start align-items-center" style={{ userSelect: "none" }}>
        <div dangerouslySetInnerHTML={{ __html: (message.message ?? "").replaceAll("\r\n", "<br/>") }} />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly align-items-center py-1">
        <Button ref={buttonRef} onClick={() => dispatch(setMessage({ show: false }))}>
          ΟΚ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMessage;
