import React from "react";
import { SiDropbox } from "react-icons/si";
import {
  addMultipleLinesToGrid,
  changeToBuffer,
  setObjectState,
  triggerGridChange,
} from "../../../../redux/features/ui/uiSlice";
import { useDispatch } from "react-redux";
import load from "load-script";
import { useSelector } from "react-redux";
import { getNewData, getObjectState } from "../../../../redux/selectors";

const DropBox = ({ tabID, element, setMenu }) => {
  const dispatch = useDispatch();
  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const dropboxOptions = {
    success: (files) => {
      const lines = [];
      for (const file of files) {
        const { link, name } = file;
        lines.push({
          SOFNAME: link,
          NAME: name,
        });
      }
      if (objectState == "view") {
        dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ value: "edit", tabID }));
      }
      const gridLines = newData?.[element.model] ?? [];
      gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
      dispatch(addMultipleLinesToGrid({ tabID, value: lines, name: element.model }));
    },
    cancel: () => console.log("cancel"),
    linkType: "preview", // or "direct"
    multiselect: true, // or true
    folderselect: true,
  };

  const handleDropBox = React.useCallback(
    (e) => {
      e.stopPropagation();
      if (window.Dropbox) {
        setMenu({ show: false });
        window.Dropbox.choose(dropboxOptions);
      }
    },
    [dropboxOptions]
  );

  React.useEffect(() => {
    load(process.env.REACT_APP_DROPBOX_SDK_URL, {
      attrs: {
        id: process.env.REACT_APP_DROPBOX_ID,
        "data-app-key": process.env.REACT_APP_DROPBOX_CLIENT_ID,
      },
    });
  }, []);
  return (
    <div className="tab-list-context-menu-action" role="button" onClick={handleDropBox}>
      <div className="d-flex align-items-center">
        <SiDropbox className="me-1" />
        <span>DropBox</span>
      </div>
    </div>
  );
};

export default DropBox;

const getName = (string) => getPathFromUrl(decodeURI(string).replace(/^.*[\\\/]/, ""));

const getPathFromUrl = (url) => url.split("?")[0];
