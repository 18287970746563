import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { set } from "../../../redux/features/settings/settingsSlice";

const App = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);

  const [browserLimit, setBrowserLimit] = React.useState(settings.browserLimit);
  const [browserPageSize, setBrowserPageSize] = React.useState(settings.browserPageSize);
  const [gridVisibleLines, setGridVisibleLines] = React.useState(settings.gridVisibleLines);

  return (
    <>
      <div style={{ flex: 1, marginRight: "2rem" }} className="d-flex flex-column align-items-center">
        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Κεντρικό μενού ανοιχτό</span>
          <input
            type="checkbox"
            defaultChecked={settings.menuOpen == 1}
            onChange={(e) => dispatch(set({ key: "menuOpen", value: e.target.checked ? 1 : 0 }))}
          />
        </div>
        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Ανοιγμα επιλογών μενού με double click</span>
          <input
            type="checkbox"
            defaultChecked={settings.doubleClickOnMenu == 1}
            onChange={(e) => dispatch(set({ key: "doubleClickOnMenu", value: e.target.checked ? 1 : 0 }))}
          />
        </div>
        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Browser record limit</span>
          <input
            type="number"
            value={browserLimit}
            onChange={(e) => setBrowserLimit(e.target.value)}
            onBlur={(e) =>
              e.target.value != settings.browserLimit &&
              e.target.value > 0 &&
              dispatch(set({ key: "browserLimit", value: parseInt(e.target.value) }))
            }
            style={{
              width: 60,
              height: 24,
              textAlign: "right",
              backgroundColor: "trasparent",
              outline: "-webkit-focus-ring-color auto 1px",
            }}
          />
        </div>
        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Browser page size</span>
          <input
            type="number"
            value={browserPageSize}
            onChange={(e) => setBrowserPageSize(e.target.value)}
            onBlur={(e) =>
              e.target.value != settings.browserPageSize &&
              e.target.value > 0 &&
              dispatch(set({ key: "browserPageSize", value: parseInt(e.target.value) }))
            }
            style={{
              width: 60,
              height: 24,
              textAlign: "right",
              backgroundColor: "trasparent",
              outline: "-webkit-focus-ring-color auto 1px",
            }}
          />
        </div>
        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Grid visible lines</span>
          <input
            type="number"
            value={gridVisibleLines}
            onChange={(e) => setGridVisibleLines(e.target.value)}
            onBlur={(e) =>
              e.target.value != settings.gridVisibleLines &&
              e.target.value > 0 &&
              dispatch(set({ key: "gridVisibleLines", value: parseInt(e.target.value) }))
            }
            style={{
              width: 60,
              height: 24,
              textAlign: "right",
              backgroundColor: "trasparent",
              outline: "-webkit-focus-ring-color auto 1px",
            }}
          />
        </div>
        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Redirection (0=Tab,1=PopUp)</span>
          <input
            type="checkbox"
            defaultChecked={settings.popUpRedirection == 1}
            onChange={(e) => dispatch(set({ key: "popUpRedirection", value: e.target.checked ? 1 : 0 }))}
          />
        </div>
      </div>
      <div style={{ flex: 1, marginRight: "0.5rem" }} className="d-flex flex-column align-items-center">
        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Ταξινόμηση με click σε ευρετήρια</span>
          <input
            type="checkbox"
            defaultChecked={settings.clickToSort == 1}
            onChange={(e) => dispatch(set({ key: "clickToSort", value: e.target.checked ? 1 : 0 }))}
          />
        </div>

        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Ταξινόμηση με click σε πλέγμα</span>
          <input
            type="checkbox"
            defaultChecked={settings.gridClickToSort == 1}
            onChange={(e) => dispatch(set({ key: "gridClickToSort", value: e.target.checked ? 1 : 0 }))}
          />
        </div>

        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Ανοιγμα εγγραφών με single click</span>
          <input
            type="checkbox"
            defaultChecked={settings.singleClickOnRecord == 1}
            onChange={(e) => dispatch(set({ key: "singleClickOnRecord", value: e.target.checked ? 1 : 0 }))}
          />
        </div>
        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Επιβεβαίωση εξόδου</span>
          <input
            type="checkbox"
            defaultChecked={settings.confirmExit == 1}
            onChange={(e) => dispatch(set({ key: "confirmExit", value: e.target.checked ? 1 : 0 }))}
          />
        </div>
        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Διατήρηση browser active search</span>
          <input
            type="checkbox"
            defaultChecked={settings.browserSearchActive == 1}
            onChange={(e) => dispatch(set({ key: "browserSearchActive", value: e.target.checked ? 1 : 0 }))}
          />
        </div>
        <div className="d-flex w-100 my-1 justify-content-between">
          <span>Ερώτηση για διαγραφή σε πλέγμα</span>
          <input
            type="checkbox"
            defaultChecked={settings.askForDeleteInGrid == 1}
            onChange={(e) => dispatch(set({ key: "askForDeleteInGrid", value: e.target.checked ? 1 : 0 }))}
          />
        </div>
      </div>
    </>
  );
};

export default App;
