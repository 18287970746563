import React from "react";
import { useSelector } from "react-redux";
import { getGadgetBrowserInfo, getGadgetCollapsed, getGadgetTLoading } from "../../../../redux/selectors";
import THead from "./gadget body/THead";
import TBody from "./gadget body/TBody";
import ScreenSpinner from "../../ScreenSpinner";
import GadgetNoData from "../GadgetNoData";
import GadgetError from "../GadgetError";

const Body = (props) => {
  const HeadRef = React.useRef(null);
  const BodyRef = React.useRef(null);

  const loading = useSelector((state) => getGadgetTLoading(state, props.id));
  const browserInfo = useSelector((state) => getGadgetBrowserInfo(state, props.id));

  const collapsed = useSelector((state) => getGadgetCollapsed(state, props.id));
  const fullScreen = useSelector((state) => state.portal.fullWindow);

  return (
    <div
      style={{
        flexGrow: 1,
        overflowY: "hidden",
        display: fullScreen && fullScreen == props.id ? "flex" : collapsed ? "none" : "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {loading && <ScreenSpinner />}
      {browserInfo ? (
        browserInfo.success ? (
          browserInfo.totalcount > 0 ? (
            <>
              <THead
                ref={HeadRef}
                BodyRef={BodyRef}
                id={props.id}
                headerGroups={props.headerGroups}
                rows={props.rows?.[0]}
              />
              <TBody
                ref={BodyRef}
                HeadRef={HeadRef}
                id={props.id}
                getTableBodyProps={props.getTableBodyProps}
                prepareRow={props.prepareRow}
                rows={props.rows}
                page={props.page}
                footerGroups={props.footerGroups}
              />
            </>
          ) : (
            <GadgetNoData id={props.id} />
          )
        ) : (
          <GadgetError error={browserInfo.error} />
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default Body;
