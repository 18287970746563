import React from "react";
import { IoMdInformationCircle } from "react-icons/io";

const NoBrowserData = () => {
  return (
    <center className="d-flex w-100  flex-column">
      <div style={{ marginTop: "5rem" }}>
        <span style={{ fontSize: "1.8rem", fontWeight: "bold", color: "#4A4A4A" }}>Info</span>
        <br />
        <IoMdInformationCircle size="128px" color="#4A4A4A" />
        <br />
        <span style={{ fontSize: "1.3rem", color: "#4A4A4A" }}>Δεν υπάρχουν δεδομένα προς απεικόνιση</span>
        <br />
        <span style={{ color: "#4A4A4A" }}>Ελέγξτε τα φίλτρα</span>
      </div>
    </center>
  );
};

export default NoBrowserData;
