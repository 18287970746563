import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/features/portal/portalSlice";

const ModalDownload = ({ show, setShow, url, fileName, format, id }) => {
  const dispatch = useDispatch();
  const [preview, setPreview] = React.useState(false);
  const handleDownLoad = async () => {
    setShow(false);
    dispatch(setLoading({ value: true, id }));

    await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      method: "POST",
      body: JSON.stringify({ url, data: { service: "download", format } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      });
    dispatch(setLoading({ value: false, id }));
  };

  React.useEffect(() => {
    if (!show) setPreview(false);
  }, [show]);

  return (
    <Modal
      show={show}
      animation={false}
      onHide={() => setShow(false)}
      dialogClassName={`${preview ? "preview-open" : ""}`}
      contentClassName="modal-content-download"
      size={preview ? "xl" : "sm"}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton className="p-2 ps-3"></Modal.Header>
      <Modal.Body className={`d-flex justify-content-center align-items-center${preview ? " p-0" : ""}`}>
        {!preview && <div>Επιτυχής δημιουργία αρχείου</div>}
        {preview && <iframe title="preview" style={{ height: "100%", width: "100%" }} src={url}></iframe>}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center p-0">
        <Button onClick={handleDownLoad}>Download</Button>
        {!preview && <Button onClick={() => setPreview((X) => !X)}>Preview</Button>}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDownload;
