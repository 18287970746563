import React from "react";
import { BsChevronDown } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters } from "../../../redux/selectors";

const S1StringsMulti = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const [editorData, setEditorData] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState("");
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const boundRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    let stringDataObj = {};
    element.editor.split("|").forEach((i) => (stringDataObj[i.split("=")[0]] = i.split("=")[1]));
    setEditorData(stringDataObj);
  }, []);

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(filters?.data?.[table]?.[key] ?? "");
      setSelectedRow(filters?.data?.[table]?.[key] ?? "");
    }
  }, [filters?.data?.[table]?.[key]]);

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const click = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!click) {
          setIsFocused(false);
          setOpenPicker(false);
        }
      };
      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handlePicker = (e) => {
    e.stopPropagation();
    setOpenPicker((x) => !x);
    setIsFocused((x) => !x);
  };

  const handleClear = () => {
    setOpenPicker(false);
    setIsFocused(false);
    dispatch(setFilter({ tabID, table, key, value: "" }));
  };

  const handleClickOnRow = (index) => {
    const SelectedArray = selectedRow.split(",");
    if (SelectedArray.indexOf(index) >= 0) {
      SelectedArray.splice(SelectedArray.indexOf(index), 1);
    } else {
      SelectedArray.push(index);
    }
    dispatch(setFilter({ tabID, table, key, value: SelectedArray.filter((x) => x != "").join(",") }));
  };
  return (
    <div className="s1stringmulti w-100" style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}>
      <div className="inputGroup">
        <label className="text-truncate" htmlFor={element.index}>
          {`${element.caption}:`}
        </label>
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              role="button"
              className={`w-100${
                element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && commandParams.readonly)}
              readOnly={true}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              // onFocus={() => setIsFocused(true)}
              // onBlur={() => setIsFocused(false)}
              onClick={!(element.readOnly || (screenState == "form" && commandParams.readonly)) && handlePicker}
            />
            {value != "" &&
              (clear || openPicker) &&
              !(element.readOnly || (screenState == "form" && commandParams.readonly)) && (
                <div onClick={handleClear}>
                  <TiDeleteOutline size="30px" role="button" />
                </div>
              )}
          </div>
          <div
            style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
            role="button"
            onClick={(e) => !(element.readOnly || (screenState == "form" && commandParams.readonly)) && handlePicker(e)}
          >
            <BsChevronDown
              className={`${element.readOnly || (screenState == "form" && commandParams.readonly) ? "disabled" : ""}`}
              color="rgb(64, 180, 252)"
              size="20px"
            />
          </div>
        </div>
      </div>
      {openPicker && (
        <div
          className="input-picker-div"
          ref={pickerRef}
          style={{
            left: boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 200 > window.innerHeight
                ? 200 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "200px",
            overflow: "hidden",
          }}
        >
          <div
            className="input-picker bg-light w-100 h-100"
            style={{
              overflow: "auto",
            }}
          >
            <table className="input-picker-table w-100">
              <tbody>
                {Object.keys(editorData).map((key) => {
                  return (
                    <tr
                      key={key}
                      className={`input-picker-table-row${
                        selectedRow.split(",").indexOf(key) >= 0 ? " input-picker-table-row-selected" : ""
                      }`}
                      onClick={() => handleClickOnRow(key)}
                    >
                      <td className="p-1" style={{ height: "40px" }}>
                        <input
                          type="checkbox"
                          className="ms-1"
                          readOnly={true}
                          role="button"
                          checked={selectedRow.split(",").indexOf(key) >= 0}
                          // style={{ height: "30px", width: "30px" }}
                        />
                      </td>
                      <td className="p-1" style={{ height: "40px" }}>
                        {editorData[key]}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default S1StringsMulti;
