import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HiX } from "react-icons/hi";
import { killTab, setTab, showTabContextMenu, updateTabOrder } from "../../redux/features/ui/uiSlice";
import {
  BsBuildingGear,
  BsFileEarmarkMedical,
  BsFillFileEarmarkSpreadsheetFill,
  BsGlobe,
  BsTable,
} from "react-icons/bs";
import TabsContextMenu from "./TabsContextMenu";
import TabSpinner from "../ui/TabSpinner";
import { HiHome } from "react-icons/hi";
import { getCurrentTabIndex, getTabs, getTabsOrder } from "../../redux/selectors";
import THeadContextMenu from "../ui/edit master/browser/THeadContextMenu";
import DashBoard from "../ui/portal/DashBoard";
import SoftoneObject from "../ui/SoftoneObject";
import ScrollLeft from "./tab list/ScrollLeft";
import ScrollRight from "./tab list/ScrollRight";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import WindowObject from "../ui/WindowObject";
import ModalSaveRecord from "./top bars/edit master/form/ModalSaveRecord";
const TabsBar = () => {
  const [showLeftButton, setShowLeftButton] = React.useState(false);
  const [showRightButton, setShowRightButton] = React.useState(false);
  const [currentTabs, setCurrentTabs] = React.useState(0);
  const tabListRef = React.useRef(null);
  const tabs = useSelector((state) => getTabs(state));
  const tabsOrder = useSelector((state) => getTabsOrder(state));
  const contextMenuShow = useSelector((state) => state.ui.contextMenu.tab.show);
  const showTHeadContectMenu = useSelector((state) => state.ui.contextMenu.thead.show);
  const [showModalSave, setShowModalSave] = React.useState(false);

  const [tabID2Delete, setTabID2Delete] = React.useState(-1);
  const currentTabID = useSelector((state) => getCurrentTabIndex(state));
  const dispatch = useDispatch();

  const handleResize = (newScreen) => {
    const tabList = tabListRef.current;
    const tabWrapper = document.getElementById("tab-list-wrapper");
    if (tabWrapper.scrollWidth > tabList.clientWidth) {
      setShowLeftButton(true);
      setShowRightButton(true);
      if (newScreen && currentTabs < tabsOrder.length)
        tabWrapper.scrollBy({
          left: tabWrapper.clientWidth,
          behavior: "smooth",
        });
    } else {
      setShowLeftButton(false);
      setShowRightButton(false);
    }
    setCurrentTabs(tabsOrder.length);
  };

  React.useEffect(() => {
    handleResize(true);
  }, [tabsOrder.length]);

  React.useEffect(() => {
    handleResize(false);
  }, [tabListRef?.current?.clientWidth]);

  const handleKillTab = (e, id) => {
    e.stopPropagation();
    if (tabs[id].objectState != "view") {
      dispatch(setTab(id));
      setTabID2Delete(id);
      setShowModalSave(true);
    } else {
      dispatch(killTab(id));
    }
  };

  const handleSetTab = (e, index) => {
    e.stopPropagation();
    dispatch(setTab(index));
  };

  const handleRightClick = (e, tab) => {
    e.preventDefault();
    dispatch(showTabContextMenu({ show: true, tab, x: e.pageX, y: e.pageY }));
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    dispatch(updateTabOrder({ source: source.index, destination: destination.index }));
  };
  return (
    <>
      <div className="tab-list" ref={tabListRef}>
        <div
          className={`tab-window me-1 dashboard${currentTabID == -1 ? " active" : ""}`}
          onClick={(e) => handleSetTab(e, -1)}
        >
          <div className="mx-2">
            <HiHome />
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <>
                {showLeftButton && <ScrollLeft />}
                <div
                  id="tab-list-wrapper"
                  className="tab-list-wrapper gap-1"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {tabsOrder.map(
                    (item, index) =>
                      !item.isWindow && (
                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={`tab-window${item.id == currentTabID ? " active" : ""}`}
                              onContextMenu={(e) => handleRightClick(e, item.id)}
                              onMouseDown={(e) => (e.button == 1 ? handleKillTab(e, item.id) : null)}
                              onClick={(e) => handleSetTab(e, item.id)}
                            >
                              <div {...provided.dragHandleProps} className="mx-2">
                                {
                                  {
                                    EditMaster: item.loading ? (
                                      <TabSpinner />
                                    ) : (
                                      <BsFileEarmarkMedical color="var(--bs-orange)" />
                                    ),
                                    Report: item.loading ? (
                                      <TabSpinner />
                                    ) : (
                                      <BsFillFileEarmarkSpreadsheetFill color="var(--bs-teal)" />
                                    ),
                                    Dialog: item.loading ? <TabSpinner /> : <BsBuildingGear color="var(--bs-danger)" />,
                                    WebPage: item.loading ? <TabSpinner /> : <BsGlobe color="rgb(64, 180, 252)" />,
                                    EditList: item.loading ? <TabSpinner /> : <BsTable color="var(--bs-yellow)" />,
                                  }[item.cmd]
                                }
                              </div>
                              <span className="text-truncate">{item.text}</span>

                              <HiX
                                className="mx-2"
                                size="1rem"
                                style={{ minHeight: "1rem", minWidth: "1rem" }}
                                onClick={(e) => handleKillTab(e, item.id)}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                  )}
                  {provided.placeholder}
                </div>
                {showRightButton && <ScrollRight />}
              </>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={`tab-panel dashboard${currentTabID == -1 ? " active" : ""}`} data-tab-id="portal">
        <DashBoard />
      </div>
      {Object.keys(tabs).map((key) =>
        !tabs[key].isWindow ? (
          <div className={`tab-panel${key == currentTabID ? " active" : ""}`} key={key} data-tab-id={key}>
            <SoftoneObject tabID={key} />
          </div>
        ) : (
          <WindowObject key={key} tabID={key} />
        )
      )}
      {showModalSave && (
        <ModalSaveRecord
          tabID={tabID2Delete}
          show={showModalSave}
          setShow={setShowModalSave}
          functionNo={() => {
            setShowModalSave(false);
            dispatch(killTab(tabID2Delete));
          }}
        />
      )}
      {contextMenuShow && <TabsContextMenu />}
      {showTHeadContectMenu && <THeadContextMenu />}
    </>
  );
};

export default TabsBar;
