import React from "react";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ModalSaveRecord from "./ModalSaveRecord";
import { setObjectState, setScreenState } from "../../../../../redux/features/ui/uiSlice";
import { getObjectState } from "../../../../../redux/selectors";

const Dialog = ({ tabID }) => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const handleGoToDialog = () => {
    if (objectState == "view") {
      goBack();
    } else {
      setShow(true);
    }
  };

  const goBack = () => {
    dispatch(setScreenState({ value: "dialog", tabID }));
    dispatch(setObjectState({ value: "view", tabID }));
  };
  return (
    <>
      <div
        onClick={handleGoToDialog}
        className="d-flex align-items-center justify-content-start"
        style={{ cursor: "pointer" }}
      >
        <FaFilter size="0.9rem" />
      </div>
      {show && (
        <ModalSaveRecord
          show={show}
          setShow={setShow}
          tabID={tabID}
          functionNo={() => {
            goBack();
            setShow(false);
          }}
        />
      )}
    </>
  );
};

export default Dialog;
