import React from "react";
import {
  getGadget,
  getGadgetBrowserInfo,
  getGadgetSelectedRow,
  getGadgetTableData,
  getURL,
} from "../../../../../redux/selectors";
import noImage from "../../../../../assets/noimage.svg";
import { useSelector, useDispatch } from "react-redux";
import { setLoading, setSelectedRow } from "../../../../../redux/features/portal/portalSlice";
import { setScreenFromJob } from "../../../../../redux/features/screens/screensSlice";
import { setNewTab } from "../../../../../redux/features/ui/uiSlice";
import { getScreens } from "../../../../../redux/selectors/screens";
const TBody = React.forwardRef(({ getTableBodyProps, prepareRow, page, id, HeadRef }, BodyRef) => {
  const dispatch = useDispatch();
  const gadget = useSelector((state) => getGadget(state, id));
  const tableData = useSelector((state) => getGadgetTableData(state, id));
  const browserInfo = useSelector((state) => getGadgetBrowserInfo(state, id));
  const url = useSelector((state) => getURL(state));
  const selectedRow = useSelector((state) => getGadgetSelectedRow(state, id));
  const screens = useSelector((state) => getScreens(state));
  const settings = useSelector((state) => state.settings.app);

  const handleRowDoubleClick = (index) => {
    const rowID = tableData[index].ZOOMINFO.substring(tableData[index].ZOOMINFO.indexOf(";") + 1);

    const object = gadget.data.OBJECT;
    const list = gadget.data.LIST;
    const rj = gadget.data.RELJOBS;
    const form = gadget.data.FORM;
    const tableColumns = gadget.tableColumns;
    const hiddenColumns = gadget.hiddenColumns;
    const command = `${object}&list=${list}${form && `&form=${form}`}&AppId=${
      process.env.REACT_APP_SOFTONE_APPID
    }&w=96&ae=2${rj && `&rj=${rj}`}`;
    const commandParams = { list, form, w: 96, AppId: process.env.REACT_APP_SOFTONE_APPID, autoLocate: true, rj };
    const value = {
      cmd: "EditMaster",
      text: gadget?.data?.title ?? gadget.name,
      idx: `gadget_${id}`,
      autoLocateID: rowID,
      command,
      commandParams,
      browserInfo,
      tableColumns,
      tableData,
      hiddenColumns,
      screenState: {},
      objectState: "view",
      isWindow: settings.popUpRedirection == 1,
    };
    dispatch(
      setScreenFromJob({
        key: `gadget_${id}`,
        value,
      })
    );
    dispatch(setNewTab(value));
  };
  return (
    <div
      className="browser-table-tbody"
      {...getTableBodyProps()}
      ref={BodyRef}
      onScroll={() => {
        const { scrollLeft } = BodyRef.current;
        HeadRef.current.scrollLeft = scrollLeft;
      }}
    >
      {page.map((row, index) => {
        prepareRow(row);
        return (
          <React.Fragment key={index}>
            <div
              {...row.getRowProps()}
              onDoubleClick={() => settings.singleClickOnRecord != 1 && handleRowDoubleClick(row.index)}
              onClick={() => {
                dispatch(setSelectedRow({ id, value: row.index }));
                settings.singleClickOnRecord == 1 && handleRowDoubleClick(row.index);
              }}
              className={`browser-table-tr ${row.index == selectedRow ? "row-selected" : ""}`}
            >
              {row.cells.map((cell) => {
                const field = browserInfo.fields.filter((item) => item.name == cell.column.dataIndex);
                return (
                  <div
                    className="browser-table-td text-truncate"
                    {...cell.getCellProps()}
                    style={{
                      ...cell.getCellProps().style,
                      // display: s1Column?.[0].hidden ? "none" : cell.getCellProps().style.display,
                      // width: cell.column.width,
                      textAlign: cell.column?.align ?? "left",
                      textOverflow: field[0]?.type == "image" ? "none" : "ellipsis",
                    }}
                  >
                    {field[0]?.type == "image" ? (
                      cell.value != "" ? (
                        <img src={`${url}/?filename=${cell.value}`} width="auto" height="60px" />
                      ) : (
                        <img src={noImage} width="auto" height="60px" />
                      )
                    ) : (
                      <span>{convertCells(cell.value, cell.column)} </span>
                    )}
                  </div>
                );
              })}
            </div>
            {row.index == tableData.length - 1 && (
              <div {...row.getRowProps()} key={index + 1} className="sums">
                {row.cells.map((cell, i) => (
                  <div
                    className="browser-table-td text-truncate p-2"
                    {...cell.getCellProps()}
                    style={{
                      ...cell.getCellProps().style,
                      display: cell.column.hidden ? "none" : cell.getCellProps().style.display,
                      width: cell.column.width,
                      textAlign: cell.column?.align ?? "left",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>{convertCells(cell.column.Footer, cell.column)}</span>
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
});

export default TBody;

const convertCells = (value, column) => {
  if (value == "") {
    return "";
  } else {
    switch (column.decs) {
      case 0: //STRINGLIST
        var index = value.indexOf("|");
        if (index > -1) {
          return value.substring(index + 1, value.length);
        } else {
          return value;
        }
      case 2: //FLOAT
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace("€", "");
      case 3: //FLOAT
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
          .replace("€", "");
      case 10: //DATETIME
        var d = new Date(value);
        return (
          [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/") +
          " " +
          [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
        );
      case 11: //DATE
        var d = new Date(value);
        return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
      default:
        return value;
    }
  }
};
