import React from "react";
import MainBar from "./application/bars/MainBar";
import { useDispatch, useSelector } from "react-redux";
import getSystemParams from "./services/getSystemParams";
import { setOutpuFormats, set as setSystemParams } from "./redux/features/systemParams/systemParamsSlice";
import { set as setRoot } from "./redux/features/roots/rootsSlice";
import getWebMenu from "./services/getWebMenu";
import { setScreenFromJob, setWebMenu } from "./redux/features/screens/screensSlice";
import Spinner from "./application/ui/Spinner";
import { setLoading } from "./redux/features/modals/modalsSlice";
import MainPanel from "./application/screens/app/MainPanel";
import ModalError from "./application/ui/modals/ModalError";
import ModalMessage from "./application/ui/modals/ModalMessage";
import ModalAbout from "./application/ui/modals/ModalAbout";
import ModalChangePassWord from "./application/ui/modals/ModalChangePassWord";
import ModalWarningForBlock from "./application/ui/modals/ModalWarningForBlock";
import ModalBlocked from "./application/ui/modals/ModalBlocked";
import ModalSettings from "./application/ui/modals/ModalSettings";
import sqlData from "./services/sqlData";
import { hasSettingsInDB, setSettings } from "./redux/features/settings/settingsSlice";
import getData from "./services/getData";
import { setNewTab } from "./redux/features/ui/uiSlice";

const App = () => {
  const [webMenuData, setWebMenuData] = React.useState();
  const [showSideBar, setShowSideBar] = React.useState(false);
  const [fetching, setFetching] = React.useState(true);
  const [enableModals, setEnableModals] = React.useState(false);
  const dispatch = useDispatch();
  const clientID = useSelector((state) => state.session?.clientID);
  const url = useSelector((state) => state.session?.url);
  const systemParams = useSelector((state) => state.systemParams);
  const user = systemParams?.userid;
  const paramsDate = systemParams?.paramsDate;
  const appLoading = useSelector((state) => state.modals.loading);

  const currentUrl = window.location.href;
  const searchParams = new URLSearchParams(currentUrl.split("?")[1]);
  const cmd = searchParams.get("cmd");
  const text = searchParams.get("text");
  const object = searchParams.get("object");
  const list = searchParams.get("list");
  const form = searchParams.get("form");
  const id = searchParams.get("id");
  const rj = searchParams.get("rj");

  let WebMenu = {};

  const fetchSystemParams = async () => {
    const today = new Date();
    if (today.toLocaleDateString() !== paramsDate) {
      const systemParams = await getSystemParams({ url, clientID });
      systemParams.paramsDate = today.toLocaleDateString();

      systemParams.success && dispatch(setSystemParams(systemParams));
    }
    const settings = await getData({ vObj: "CCCD1WEBCRMSETTINGS", key: systemParams.wa });
    if (settings.success) {
      dispatch(hasSettingsInDB());
      dispatch(
        setSettings({
          ...settings.data.CCCD1WEBCRMSETTINGS[0],
          calendarColorZones: settings.data.CCCD1WEBCRMCALENDARCOLORZONES,
        })
      );
    }
  };

  const fetchWebMenu = async () => {
    dispatch(setLoading(true));
    const webMenu = await getWebMenu({ user });
    if (webMenu.success) {
      setWebMenuData(webMenu.menu);
      deserializeMenu(webMenu.menu);
      dispatch(setWebMenu(WebMenu));
      openRedirect();
      dispatch(setOutpuFormats(webMenu.outputformats));
    } else {
      console.log(webMenu);
    }
    dispatch(setLoading(false));
    setFetching(false);
    setEnableModals(true);
  };

  const openRedirect = () => {
    if (cmd && object && form && list && id) {
      const command = `${object}&list=${list}${form && `&form=${form}`}&AppId=${
        process.env.REACT_APP_SOFTONE_APPID
      }&w=96&ae=2${rj && `&rj=${rj}`}`;
      const commandParams = { list, form, w: 96, AppId: process.env.REACT_APP_SOFTONE_APPID, autoLocate: true, rj };

      const value = {
        cmd,
        text,
        idx: `url_${id}`,
        autoLocateID: id,
        rj,
        command,
        commandParams,
        screenState: {},
        objectState: "view",
      };
      dispatch(
        setScreenFromJob({
          key: `url_${id}`,
          value,
        })
      );
      dispatch(setNewTab(value));
    }
  };

  const deserializeMenu = (elements) => {
    const pattern = /^\{([^{}]+)\}(.*)/;
    elements.forEach((item) => {
      if (!item?.children) {
        const match = item.command.match(pattern);
        let customParams = {};
        if (match) {
          const keyValuePairs = match[1].split(",");
          keyValuePairs.forEach((pair) => {
            const [key, value] = pair.split(":");
            if (key.toLowerCase() == "editoptions") {
              const editArray = value.toLowerCase().split(";");
              editArray.forEach((x) => (customParams[x.replaceAll("'", "").replaceAll('"', "")] = true));
            } else {
              customParams[key.toLowerCase()] = value.toLowerCase();
            }
          });
        }

        item.command = match ? match[2] : item.command;
        const params = new URLSearchParams(item.command);
        const properties = {};
        for (const [key, value] of params.entries()) {
          properties[key] = value;
          if (key == "root" && value == 1)
            dispatch(
              setRoot({
                key: item.command.split("&")[0],
                value: {
                  command: `${item.command.split("&")[0]}${params.get("list") ? `&list=${params.get("list")}` : ""}${
                    params.get("form") ? `&form=${params.get("form")}` : ""
                  }${params.get("rj") ? `&rj=${params.get("rj")}` : ""}
                  &AppId=${process.env.REACT_APP_SOFTONE_APPID}`,
                  list: params.get("list"),
                  form: params.get("form"),
                  AppId: process.env.REACT_APP_SOFTONE_APPID,
                  rj: params.get("rj"),
                  text: item.text,
                },
              })
            );
        }
        item.commandParams = { ...properties, ...customParams };
        item.screenState = { present: undefined, past: undefined };
        item.objectState = "view";
        item.browseExpandable = item.cmd === "EditMaster" ? true : undefined;
        WebMenu[item.idx] = item;
      } else {
        deserializeMenu(item.children);
      }
    });
  };

  React.useEffect(() => {
    fetchSystemParams();
    fetchWebMenu();
  }, []);

  return (
    <>
      <div className="App d-flex flex-column w-100 h-100">
        {appLoading && <Spinner />}
        <MainBar webMenuData={webMenuData} show={showSideBar} setShow={setShowSideBar} />
        {!fetching && <MainPanel webMenuData={webMenuData} />}
        <ModalError />
        <ModalMessage />
        <ModalAbout />
        <ModalChangePassWord />
        <ModalSettings />
        {enableModals && <ModalWarningForBlock />}
        {enableModals && <ModalBlocked />}
      </div>
    </>
  );
};

export default App;
