import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../redux/features/modals/modalsSlice";

const ModalError = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.modals.error);
  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={error.show}
      animation={false}
      onHide={() => dispatch(setError({ show: false }))}
      centered
    >
      <Modal.Header className="p-2 ps-3" closeButton style={{ userSelect: "none" }}>
        Σφάλμα
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-start align-items-center" style={{ userSelect: "none" }}>
        <div dangerouslySetInnerHTML={{ __html: (error.message ?? "").replaceAll("\r\n", "<br/>") }} />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly align-items-center p-0">
        <Button onClick={() => dispatch(setError({ show: false }))}>ΟΚ</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalError;
