import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDetailGrid,
  changeToBuffer,
  deleteLineFromGrid,
  setCurrentDetailLine,
  setObjectState,
  triggerGridChange,
} from "../../../redux/features/ui/uiSlice";
import {
  getCommandParams,
  getCurrentGridLine,
  getFormDesign,
  getNewData,
  getObjectState,
  getRecData,
} from "../../../redux/selectors";
import { useBlockLayout, useFlexLayout, useResizeColumns, useRowSelect, useSortBy, useTable } from "react-table";
import ModaDetailLine from "./ModaDetailLine";
import DeleteLineModal from "./DeleteLineModal";
import { BiSortUp, BiSortDown } from "react-icons/bi";
import { ImBin } from "react-icons/im";
import { AiOutlinePlus } from "react-icons/ai";
import { calculate } from "../../../services/calculate";

const S1DetailGrid = ({ element, tabID }) => {
  const dispatch = useDispatch();

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const settings = useSelector((state) => state.settings.app);
  const dataApi = useSelector((state) => getRecData(state, tabID)?.[element.model]);
  const currentGridLine = useSelector((state) => getCurrentGridLine(state, tabID, element.model));
  const columnsApi = [
    ...element.columns.map((item) => ({
      ...item,
      Header: item.caption,
      accessor: item.name.split(".")[1],
      hidden: !item.visible,
      width: formDesign.model[element.model].fields.filter((x) => x.name == item.name.split(".")[1])[0].size * 4,
    })),
  ];

  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const theadRef = React.useRef(null);
  const tBodyRef = React.useRef(null);

  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const columns = React.useMemo(() => columnsApi ?? [], []);
  const data = React.useMemo(() => dataApi ?? [], [dataApi]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleSortBy } = useTable(
    {
      columns,
      data,
    },
    // useFlexLayout,
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    useRowSelect
  );

  React.useEffect(() => {
    dispatch(addDetailGrid({ tabID, value: element }));
  }, []);

  const handleRowClick = (value) => {
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: { ...value, name: element.model } }));
  };

  const handleRowDoubleClick = (value) => {
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: { ...value, name: element.model } }));
    setOpenViewModal(true);
  };

  const handleDelete = async (lineIndex) => {
    if (settings.askForDeleteInGrid == 1) {
      setOpenDeleteModal(true);
    } else {
      if (objectState == "view") {
        dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ value: "edit", tabID }));
      }
      var gridLines = newData?.[element.model] ?? [];
      gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
      dispatch(deleteLineFromGrid({ tabID, name: element.model, lineIndex }));
      await calculate(tabID);
      dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
    }
  };

  return (
    <div
      {...getTableProps()}
      style={{ ...getTableProps().style, border: "1px solid #b9b9b9" }}
      className="browser-table w-100"
    >
      <div className="browser-table-thead" ref={theadRef}>
        {headerGroups.map((headerGroup) => (
          <div
            className="browser-table-tr"
            {...headerGroup.getHeaderGroupProps()}
            style={{
              ...headerGroup.getHeaderGroupProps().style,
              paddingRight: (tBodyRef?.current?.offsetWidth ?? 0) - (tBodyRef?.current?.clientWidth ?? 0),
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center text-light"
              // onContextMenu={(e) => handleRightClick(e, column.id.replace("_", "."))}
              // key={index}
              style={{
                padding: "0.5rem",
                boxSizing: "border-box",
                minWidth: "60px",
                position: "relative",
                display: "block",
              }}
            >
              <AiOutlinePlus
                role={commandParams.readonly ? "none" : "button"}
                onMouseEnter={(e) => {
                  if (!commandParams.readonly) {
                    e.stopPropagation();
                    e.target.style.color = "rgb(64, 180, 252)";
                  }
                }}
                onMouseLeave={(e) => {
                  if (!commandParams.readonly) {
                    e.stopPropagation();
                    e.target.style.color = "";
                  }
                }}
                onClick={() => {
                  if (!commandParams.readonly) {
                    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
                    setOpenViewModal(true);
                  }
                }}
              />
            </div>
            {headerGroup.headers.map((column, index) => {
              return (
                <div
                  {...column.getHeaderProps()}
                  className="browser-table-th text-truncate"
                  // onContextMenu={(e) => handleRightClick(e, column.id.replace("_", "."))}
                  key={index}
                  style={{
                    ...column.getHeaderProps().style,
                    display: column?.hidden ? "none" : "block",
                    backgroundColor: column.isSorted ? "var(--bs-gray-600)" : "Var(--bs-gray-dark)",
                  }}
                >
                  <div
                    onClick={() => {
                      settings.gridClickToSort == 1 && toggleSortBy(column.id, column.isSortedDesc ? false : true);
                    }}
                    className="d-flex align-items-center text-truncate"
                    style={{
                      justifyContent: column.align ?? "flex-start",
                    }}
                  >
                    {column.render("Header")}
                    {column.isSorted &&
                      (column.isSortedDesc ? (
                        <BiSortDown className="ms-1" size="1rem" style={{ minHeight: "1rem", minWidth: "1rem" }} />
                      ) : (
                        <BiSortUp className="ms-1" size="1rem" style={{ minHeight: "1rem", minWidth: "1rem" }} />
                      ))}
                  </div>
                  <div
                    {...column.getResizerProps()}
                    className={`browser-table-resizer ${column.isResizing ? "isResizing" : ""}`}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div
        className="browser-table-tbody"
        {...getTableBodyProps()}
        style={{
          ...getTableBodyProps().style,
          minHeight: settings.gridVisibleLines * 40,
          maxHeight: settings.gridVisibleLines * 40,
        }}
        ref={tBodyRef}
        onScroll={() => {
          const { scrollLeft } = tBodyRef.current;
          theadRef.current.scrollLeft = scrollLeft;
        }}
      >
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <div
              {...row.getRowProps()}
              onDoubleClick={() => handleRowDoubleClick({ line: row.original, index: dataApi.indexOf(row.original) })}
              onClick={() => handleRowClick({ index: dataApi.indexOf(row.original) })}
              className={`browser-table-tr ${currentGridLine?.index == row?.index ? "row-selected" : ""}`}
            >
              <div
                className="browser-table-td text-truncate"
                style={{
                  boxSizing: "border-box",
                  minWidth: "60px",
                  position: "relative",
                  display: "block",
                  textAlign: "right",
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <ImBin
                    role={commandParams.readonly ? "none" : "button"}
                    color={commandParams.readonly ? "var(--bs-secondary)" : "var(--bs-danger)"}
                    onClick={() => (commandParams.readonly ? undefined : handleDelete(dataApi.indexOf(row.original)))}
                  />
                  <span>{index + 1}</span>
                </div>
              </div>
              {row.cells.map((cell) => {
                return (
                  <div
                    className="browser-table-td text-truncate"
                    {...cell.getCellProps()}
                    style={{
                      ...cell.getCellProps().style,
                      display: cell?.hidden ? "none" : "block",
                      textAlign: cell?.align ?? "left",
                    }}
                  >
                    {convertCells(cell)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <ModaDetailLine tabID={tabID} show={openViewModal} setShow={setOpenViewModal} element={element} />
      <DeleteLineModal tabID={tabID} show={openDeleteModal} setShow={setOpenDeleteModal} element={element} />
    </div>
  );
};

export default S1DetailGrid;

const convertCells = (cell) => {
  if (cell.value) {
    if (cell.value == "") {
      return "";
    } else {
      switch (cell?.column.xtype) {
        case "stringlist": //STRINGLIST
        case "s1memorytable":
          var index = cell.value.indexOf("|");
          if (index > -1) return cell.value.substring(index + 1, cell.value.length);
          else return cell.value;
        case "s1checkbox":
          return <input type="checkbox" checked={cell.value == 1} readOnly />;
        case "s1numberfield":
        case "s1intfield":
          return parseFloat(cell.value)
            .toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: cell?.column.decimals ?? 2,
              maximumFractionDigits: cell?.column.decimals ?? 2,
            })
            .replace("€", "");
        case "s1strings":
          const array = cell.column.editor.split("|");
          if (array.length > 0) {
            const result = {};
            array.forEach((pair) => {
              const [key, value] = pair.split("=").map((part) => part.trim()); // Split each key-value pair by the equal sign and trim whitespace
              result[key] = value; // Assign the value to the corresponding key in the object
            });
            return result?.[cell.value] ?? cell.value;
          } else return cell.value;
        case 10: //DATETIME
          var d = new Date(cell.value);
          return (
            [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/") +
            " " +
            [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
          );
        case "s1calendardate": //DATE
          var d = new Date(cell.value);
          return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
        default:
          return cell.value;
      }
    }
  } else {
    return "";
  }
};
