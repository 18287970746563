import React from "react";
import { BsFillCalculatorFill } from "react-icons/bs";
import { getCalc } from "../../../../../redux/selectors";
import { useSelector } from "react-redux";
import { calculate } from "../../../../../services/calculate";

const CalculateRecord = ({ tabID }) => {
  const shouldCalc = useSelector((state) => getCalc(state, tabID));
  return (
    <React.Fragment>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => shouldCalc && calculate(tabID)}
        style={{
          userSelect: "none",
          cursor: shouldCalc ? "pointer" : "default",
          opacity: shouldCalc ? 1 : 0.5,
        }}
      >
        <BsFillCalculatorFill size="1.2rem" className="me-1" />
        Υπολογισμός
      </div>
    </React.Fragment>
  );
};

export default CalculateRecord;
