import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { TiDeleteOutline } from "react-icons/ti";
import { BsCalendar3 } from "react-icons/bs";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";

const S1CalendarDate = ({ element, screenState, tabID, grid }) => {
  const isDateTime = element.dataType == "DateTime";
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const currentGridLine = grid?.currentLine;

  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];
  const data = useSelector((state) => getRecData(state, tabID));

  const dateRef = React.useRef(null);

  const [clear, showClear] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  const handleClear = () => {
    setValue("");
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else {
      if (objectState == "view") {
        dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ tabID, value: "edit" }));
      }
      handleCalculate("");
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      if (data?.[table]?.[0]?.[key] && data?.[table]?.[0]?.[key] != "") {
        setValue(dateConverter(data?.[table]?.[0]?.[key]));
      } else {
        setValue("");
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if (grid) {
      if (currentGridLine?.line?.[key] && currentGridLine?.line?.[key] != "") {
        setValue(dateConverter(currentGridLine?.line?.[key]));
      } else {
        setValue("");
      }
    }
  }, [currentGridLine?.line?.[key]]);

  const handleChange = (value) => {
    if (screenState == "dialog") {
      setValue(value);
      dispatch(setFilter({ tabID, table, key, value: isDateTime ? dateTimeConverter(value) : dateConverter(value) }));
    } else if (screenState == "form") {
      if (objectState == "view") {
        dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ tabID, value: "edit" }));
      }
      handleCalculate(isDateTime ? dateTimeConverter(value) : dateConverter(value));
    }
  };
  return (
    <div
      className="s1calendardate w-100"
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        {element.caption != "" && (
          <label className="text-truncate" htmlFor={element.index}>
            {`${element.caption}:`}
          </label>
        )}
        <div
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100" onMouseEnter={() => showClear(true)} onMouseLeave={() => showClear(false)}>
            <input
              ref={dateRef}
              type="date"
              className={`w-100${value != "" ? " date-input-has-value" : ""}${
                element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
              }`}
              disabled={element.readOnly || (screenState == "form" && commandParams.readonly)}
              readOnly={element.readOnly || (screenState == "form" && commandParams.readonly)}
              value={value}
              name={element.name}
              onChange={(e) => !element.readOnly && handleChange(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onClick={() => {
                !element.readOnly && dateRef.current.showPicker();
                !element.readOnly && dateRef.current.click();
                !element.readOnly && dateRef.current.focus();
              }}
            />
            {value != "" && clear && !element.readOnly && (
              <div onClick={handleClear}>
                <TiDeleteOutline size="30px" role="button" />
              </div>
            )}
          </div>
          {!(element.readOnly || (screenState == "form" && commandParams.readonly)) && (
            <div
              style={{ width: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
              onClick={() => {
                dateRef.current.showPicker();
                dateRef.current.click();
                dateRef.current.focus();
              }}
            >
              <BsCalendar3
                className={element.readOnly || (screenState == "form" && commandParams.readonly) ? "disabled" : ""}
                color="rgb(64, 180, 252)"
                size="20px"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default S1CalendarDate;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};

const dateTimeConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return `${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-")} 00:00:00`;
  } else {
    return value;
  }
};
