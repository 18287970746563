import React from "react";
import SoftoneObject from "./SoftoneObject";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { killTab } from "../../redux/features/ui/uiSlice";

const WindowObject = ({ tabID }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      fullscreen={true}
      dialogClassName="p-4"
      contentClassName="rounded-2"
      centered
      show={true}
      size="xl"
      animation={false}
      onHide={() => dispatch(killTab(tabID))}
    >
      <Modal.Header closeButton className="p-2 ps-3" />
      <Modal.Body
        className="m-1 p-0 bg-light"
        style={{ display: "flex", flex: "1", flexDirection: "column", position: "relative", overflow: "hidden" }}
      >
        <SoftoneObject tabID={tabID} />
      </Modal.Body>
    </Modal>
  );
};

export default WindowObject;
