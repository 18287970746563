import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { calculate } from "../../../services/calculate";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";

const S1IntField = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID)?.data);
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const currentGridLine = grid?.currentLine;

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];

  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      console.log(model);
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      if (data?.[table]?.[0]?.[key] == "") {
        setValue("");
      } else if (data?.[table]?.[0]?.[key]) {
        setValue(data?.[table]?.[0]?.[key]);
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if ((currentGridLine?.line?.[key] || currentGridLine?.line?.[key] == "") && grid)
      setValue(currentGridLine?.line?.[key]);
  }, [currentGridLine?.line?.[key]]);

  const handleChange = (e) => {
    setIsFocused(false);
    if (screenState == "dialog") {
      if (filters?.[table]?.[key] != e.target.value) {
        dispatch(setFilter({ tabID, table, key, value: e.target.value }));
      }
    } else if (screenState == "form") {
      var preValue = data?.[table]?.[0]?.[key] ?? "";
      var curValue = e.target.value;
      setValue(curValue == "" ? "" : curValue);
      if (!grid) {
        if (preValue != "" && curValue != "" && preValue != curValue) {
          if (objectState == "view") {
            dispatch(changeToBuffer({ tabID }));
            dispatch(setObjectState({ value: "edit", tabID }));
          }
          handleCalculate(curValue);
        }
      } else {
        handleCalculate(curValue);
      }
    }
  };

  function handleInput(e) {
    const { value } = e.target;
    setValue(value.replace(/\D/g, ""));
  }

  return (
    <div
      className="s1intfield w-100"
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <div className="inputGroup">
        {element.caption != "" && (
          <label className="text-truncate" htmlFor={element.index}>
            {`${element.caption}:`}
          </label>
        )}
        <div
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}${
            element.required && value == "" ? " required" : ""
          }`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
        >
          <input
            className={`w-100${!element.readOnly && !commandParams.readonly ? "" : " disabled"}`}
            autoComplete="off"
            style={{ textAlign: "end" }}
            name={element.name}
            disabled={element.readOnly || commandParams.readonly}
            readOnly={element.readOnly || commandParams.readonly}
            value={value}
            onChange={(e) => handleInput(e)}
            onFocus={() => setIsFocused(true)}
            onBlur={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default S1IntField;

const formatNumber = (value, decimals) => {
  return parseFloat(value)
    .toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    .replace(/€|\u20AC|\xA0/g, "");
};
