import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmLogOut = ({ show, setShow, handleLogOut }) => {
  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={show}
      animation={false}
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Header className="p-2 ps-3" closeButton style={{ userSelect: "none" }}>
        Ερώτηση
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-start align-items-center" style={{ userSelect: "none" }}>
        Είστε σίγουροι για τον τερματισμό της εφαρμογής;
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center p-0">
        <Button
          onClick={() => {
            setShow(false);
            handleLogOut();
          }}
        >
          Αποσύνδεση
        </Button>
        <Button onClick={() => setShow(false)}>Ακύρωση</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmLogOut;
