import React from "react";
import { Dropdown } from "react-bootstrap";
import { AiOutlinePoweroff } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/features/modals/modalsSlice";
import axios from "axios";
import { clear as clearSession } from "../../../redux/features/session/sessionSlice";
import ConfirmLogOut from "../../ui/modals/ConfirmLogOut";

const LogOut = () => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const settings = useSelector((state) => state.settings.app);
  const [show, setShow] = React.useState(false);

  const handleLogOut = async () => {
    dispatch(setLoading(true));
    await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/logout`, {
      key: session?.loginid,
    });
    dispatch(clearSession());
    dispatch(setLoading(false));
  };

  return (
    <>
      <Dropdown.Item href="#" onClick={(e) => (settings.confirmExit == 1 ? setShow(true) : handleLogOut())}>
        <div className="d-flex justify-content-start align-items-center gap-1">
          <AiOutlinePoweroff />
          Αποσύνδεση
        </div>
      </Dropdown.Item>
      <ConfirmLogOut show={show} setShow={setShow} handleLogOut={handleLogOut} />
    </>
  );
};

export default LogOut;
