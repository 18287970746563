import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDetailGrid,
  changeToBuffer,
  deleteLineFromGrid,
  setCurrentDetailLine,
  setObjectState,
  triggerGridChange,
} from "../../../redux/features/ui/uiSlice";
import {
  getCurrentGridLine,
  getFormDesign,
  getGridHiddenColumns,
  getNewData,
  getObjectState,
  getRecData,
} from "../../../redux/selectors";
import { useBlockLayout, useResizeColumns, useSortBy, useTable } from "react-table";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { ImBin } from "react-icons/im";
import { calculate } from "../../../services/calculate";
import DeleteLineModal from "../detail grid/DeleteLineModal";
import NewButton from "./NewButton";

const S1RelDocs = ({ tabID, element }) => {
  const dispatch = useDispatch();
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const settings = useSelector((state) => state.settings.app);
  const dataApi = useSelector((state) => getRecData(state, tabID)?.[element.model]);
  const currentGridLine = useSelector((state) => getCurrentGridLine(state, tabID, element.model));

  const theadRef = React.useRef(null);
  const tBodyRef = React.useRef(null);

  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const initialState = {
    hiddenColumns: useSelector((state) => getGridHiddenColumns(state, tabID, element.model)) ?? [],
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "TYPE",
        hidden: false,
      },
      {
        Header: "Name",
        accessor: "NAME",
        hidden: false,
      },
      {
        Header: "Link",
        accessor: "SOFNAME",
        hidden: false,
        width: formDesign.model[element.model].fields.filter((x) => x.name == "SOFNAME")[0].size,
      },
    ],
    [formDesign]
  );

  const data = React.useMemo(
    () =>
      dataApi
        ? dataApi.map((item) => ({
            ...item,
            TYPE: getType(getName(item.NAME ?? item.SOFNAME)),
            NAME: item.NAME ?? getName(item.SOFNAME),
          }))
        : [],
    [dataApi]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleSortBy } = useTable(
    {
      columns,
      data,
      initialState,
    },
    // useFlexLayout,
    useBlockLayout,
    useResizeColumns,
    useSortBy
  );

  React.useEffect(() => {
    dispatch(addDetailGrid({ tabID, value: { ...element, hiddenColumns: ["LINENUM"] } }));
  }, []);

  const handleRowDoubleClick = (index) => {
    if (isValidUrl(dataApi[index].SOFNAME)) {
      window.open(dataApi[index].SOFNAME);
    }
  };

  const handleRowClick = (value) => {
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: { ...value, name: element.model } }));
  };

  const handleDelete = async (lineIndex) => {
    if (settings.askForDeleteInGrid == 1) {
      setOpenDeleteModal(true);
    } else {
      if (objectState == "view") {
        dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ value: "edit", tabID }));
      }
      var gridLines = newData?.[element.model] ?? [];
      gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
      dispatch(deleteLineFromGrid({ tabID, name: element.model, lineIndex }));
      await calculate(tabID);
      dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
    }
  };
  return (
    <div
      {...getTableProps()}
      style={{ ...getTableProps().style, border: "1px solid #b9b9b9" }}
      className="browser-table w-100"
    >
      <div className="browser-table-thead" ref={theadRef}>
        {headerGroups.map((headerGroup) => (
          <div
            className="browser-table-tr"
            {...headerGroup.getHeaderGroupProps()}
            style={{
              ...headerGroup.getHeaderGroupProps().style,
              paddingRight: (tBodyRef?.current?.offsetWidth ?? 0) - (tBodyRef?.current?.clientWidth ?? 0),
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center text-light"
              // onContextMenu={(e) => handleRightClick(e, column.id.replace("_", "."))}
              // key={index}
              style={{
                padding: "0.5rem",
                boxSizing: "border-box",
                minWidth: "60px",
                position: "relative",
                display: "block",
              }}
            >
              <NewButton tabID={tabID} element={element} />
            </div>
            {headerGroup.headers.map((column, index) => {
              return (
                <div
                  {...column.getHeaderProps()}
                  className="browser-table-th text-truncate"
                  // onContextMenu={(e) => handleRightClick(e, column.id.replace("_", "."))}
                  key={index}
                  style={{
                    ...column.getHeaderProps().style,
                    display: column?.hidden ? "none" : "block",
                    backgroundColor: column.isSorted ? "var(--bs-gray-600)" : "Var(--bs-gray-dark)",
                  }}
                >
                  <div
                    onClick={(e) => {
                      settings.gridClickToSort == 1 && toggleSortBy(column.id, column.isSortedDesc ? false : true);
                    }}
                    className="d-flex align-items-center text-truncate"
                    style={{
                      justifyContent: column.align ?? "flex-start",
                    }}
                  >
                    {column.render("Header")}
                    {column.isSorted &&
                      (column.isSortedDesc ? (
                        <BiSortDown className="ms-1" size="1rem" style={{ minHeight: "1rem", minWidth: "1rem" }} />
                      ) : (
                        <BiSortUp className="ms-1" size="1rem" style={{ minHeight: "1rem", minWidth: "1rem" }} />
                      ))}
                  </div>
                  <div
                    {...column.getResizerProps()}
                    className={`browser-table-resizer ${column.isResizing ? "isResizing" : ""}`}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div
        className="browser-table-tbody"
        {...getTableBodyProps()}
        style={{
          ...getTableBodyProps().style,
          minHeight: settings.gridVisibleLines * 40,
          maxHeight: settings.gridVisibleLines * 40,
        }}
        ref={tBodyRef}
        onScroll={() => {
          const { scrollLeft } = tBodyRef.current;
          theadRef.current.scrollLeft = scrollLeft;
        }}
      >
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <div
              {...row.getRowProps()}
              onDoubleClick={() => handleRowDoubleClick(row.index)}
              onClick={() => handleRowClick({ index: row.index })}
              className={`browser-table-tr ${currentGridLine?.index == row?.index ? "row-selected" : ""}`}
            >
              <div
                className="browser-table-td text-truncate"
                style={{
                  boxSizing: "border-box",
                  minWidth: "60px",
                  position: "relative",
                  display: "block",
                  textAlign: "right",
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <ImBin role="button" color="var(--bs-danger)" onClick={() => handleDelete(row.index)} />
                  <span>{index + 1}</span>
                </div>
              </div>
              {row.cells.map((cell) => {
                return (
                  <div
                    className="browser-table-td text-truncate"
                    {...cell.getCellProps()}
                    style={{
                      ...cell.getCellProps().style,
                      display: cell?.hidden ? "none" : "block",
                      textAlign: cell?.align ?? "left",
                    }}
                  >
                    {cell.value}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {/* <ModaDetailLine tabID={tabID} show={openViewModal} setShow={setOpenViewModal} element={element} /> */}
      <DeleteLineModal tabID={tabID} show={openDeleteModal} setShow={setOpenDeleteModal} element={element} />
    </div>
  );
};

export default S1RelDocs;

const isFolder = (string) => string.replace(/^.*[\\\/]/, "").indexOf(".") == -1;

const getType = (string) => {
  if (isFolder(string)) {
    return "folder";
  } else {
    const path = getPathFromUrl(string.replace(/^.*[\\\/]/, ""));
    return "." + path.split(".")[path.split(".").length - 1].toLowerCase();
  }
};

const getName = (string) => getPathFromUrl(decodeURI(string).replace(/^.*[\\\/]/, ""));

const getPathFromUrl = (url) => url.split("?")[0];

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
