import React from "react";
import authenticate from "../../../services/authenticate";
import getSystemParams from "../../../services/getSystemParams";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BiLogIn } from "react-icons/bi";
import { set as setParams } from "../../../redux/features/systemParams/systemParamsSlice";
import { set as setSession } from "../../../redux/features/session/sessionSlice";
import CrmDropDown from "../../ui/CrmDropDown";
import Spinner from "../../ui/Spinner";
import PowererBy from "../../../assets/PowererBy";

const Authenticate = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const [loading, setLoading] = React.useState(true);
  const [uniqueAuth, setUniqueAuth] = React.useState(false);

  const [company, setCompany] = React.useState({ code: "", name: "" });
  const [branch, setBranch] = React.useState({ code: "", name: "" });
  const [user, setUser] = React.useState({ code: "", name: "" });

  const [companies, setCompanies] = React.useState([]);
  const [branches, setBranches] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const [error, setError] = React.useState({ show: false, message: "" });

  const onCompanyChange = (company) => {
    let branchesArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if (login.objs[i].COMPANY == company.code) {
        branchesArray.push(login.objs[i]);
      }
    }
    branchesArray = [...new Map(branchesArray.map((item) => [item.BRANCH, item])).values()];
    setBranches(branchesArray);
    setBranch({
      code: branchesArray[0].BRANCH,
      name: branchesArray[0].BRANCHNAME,
    });

    let userArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if (login.objs[i].COMPANY == company.code && login.objs[i].BRANCH == branch.code) {
        userArray.push(login.objs[i]);
      }
    }
    userArray = [...new Map(userArray.map((item) => [item.REFID, item])).values()];
    setUsers(userArray);
    setUser({
      code: userArray[0].REFID,
      name: userArray[0].REFIDNAME,
    });
  };

  const onBranchChange = (branch) => {
    let userArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if ((login.objs[i].COMPANY = company.code) && (login.objs[i].BRANCH = branch.code)) {
        userArray.push(login.objs[i]);
      }
    }
    setUsers([...new Map(userArray.map((item) => [item.REFID, item])).values()]);
  };

  const handleAuthenticate = async (e) => {
    e && e.preventDefault();
    setError({ show: false, message: "" });
    setLoading(true);
    try {
      const Company = company.code === "" ? login.objs[0].COMPANY : company.code;
      const CompanyName = company.name === "" ? login.objs[0].COMPANYNAME : company.name;
      const Branch = branch.code === "" ? login.objs[0].BRANCH : branch.code;
      const BranchName = branch.name === "" ? login.objs[0].BRANCHNAME : branch.name;
      const RefID = user.code === "" ? login.objs[0].REFID : user.code;
      const RefIDName = user.name === "" ? login.objs[0].REFIDNAME : user.name;
      const Authenticate = await authenticate({
        log: {
          username: RefIDName,
          company: CompanyName,
          branch: BranchName,
        },
        username: login.username,
        company: Company,
        branch: Branch,
        refID: RefID,
        module: 0,
        sn: login.sn,
        clientID: login.clientID,
      });
      if (Authenticate.success) {
        const systhemParams = await getSystemParams({
          url: `http://${login.sn}.oncloud.gr/s1services`,
          clientID: Authenticate.clientID,
        });
        if (systhemParams.success) {
          const today = new Date();
          systhemParams.paramsDate = today.toLocaleDateString();
          dispatch(setParams(systhemParams));
          Authenticate.refIDName = RefIDName;
          dispatch(setSession(Authenticate));
        } else {
          setError({ show: true, message: systhemParams.error });
          setLoading(false);
        }
      } else {
        setError({ show: true, message: Authenticate.error });
        setLoading(false);
      }
    } catch (error) {
      setError({ show: true, message: error.message });
      setLoading(false);
    }
  };

  const inputs = [
    {
      controlId: "company",
      label: "Εταιρεία",
      type: "text",
      value: company.name,
      keyValue: company.code,
      inputMode: "none",
      data: companies,
      function: setCompany,
      functionOnChange: onCompanyChange,
    },
    {
      controlId: "branch",
      label: "Υπ/μα",
      type: "text",
      value: branch.name,
      keyValue: branch.code,
      inputMode: "none",
      data: branches,
      function: setBranch,
      functionOnChange: onBranchChange,
    },
    {
      controlId: "user",
      label: "Χρήστης",
      type: "text",
      value: user.name,
      keyValue: user.code,
      inputMode: "none",
      data: users,
      function: setUser,
      functionOnChange: () => {},
    },
  ];

  React.useEffect(() => {
    var coomm = login?.objs ? [...new Map(login.objs.map((item) => [item.COMPANY, item])).values()] : [];
    setCompanies(coomm);
    setCompany({
      code: coomm[0].COMPANY,
      name: coomm[0].COMPANYNAME,
    });
    let objs = login.objs;
    let branchesArray = [];
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].COMPANY == coomm[0].COMPANY) {
        branchesArray.push(objs[i]);
      }
    }
    branchesArray = [...new Map(branchesArray.map((item) => [item.BRANCH, item])).values()];
    setBranches(branchesArray);
    setBranch({
      code: branchesArray[0].BRANCH,
      name: branchesArray[0].BRANCHNAME,
    });

    let userArray = [];
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].COMPANY == coomm[0].COMPANY && objs[i].BRANCH == branchesArray[0].BRANCH) {
        userArray.push(objs[i]);
      }
    }
    userArray = [...new Map(userArray.map((item) => [item.REFID, item])).values()];
    setUsers(userArray);
    setUser({
      code: userArray[0].REFID,
      name: userArray[0].REFIDNAME,
    });
    if (coomm.length === 1) {
      setUniqueAuth(true);
      setTimeout(handleAuthenticate, 200);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div className="portal-background d-flex flex-column align-items-center justify-content-center h-100 bg-light">
      {loading && <Spinner />}
      <Card className="shadow w-100 p-4" style={{ maxWidth: "450px" }}>
        <Form onSubmit={handleAuthenticate}>
          {inputs.map((item, index) => (
            <CrmDropDown
              label={item.label}
              type={item.type}
              name={item.controlId}
              inputMode={item.inputMode}
              required
              value={item.value}
              key={index}
              data={item.data}
              functionOnChange={item.functionOnChange}
              onChange={item.function}
            />
          ))}
          <Button
            disabled={(uniqueAuth && !error.show) || loading}
            className="w-100 hoverIconAnimation-s mt-1"
            type="submit"
            variant={`${uniqueAuth ? "secondary" : "primary"}`}
          >
            <b>Σύνδεση</b> <BiLogIn />
          </Button>
        </Form>
      </Card>
      {error.show && (
        <Alert
          variant="danger"
          className="mt-4"
          style={{ width: "450px" }}
          onClose={() => setError({ show: false, message: "" })}
          dismissible
        >
          <p dangerouslySetInnerHTML={{ __html: error.message }} />
        </Alert>
      )}
      <PowererBy />
    </div>
  );
};

export default Authenticate;
