import React from "react";
import { Container } from "react-bootstrap";
import { HiSearch } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setLoading, setReportData, setReportInfo, setScreenState } from "../../../../redux/features/ui/uiSlice";
import serviceGetReportInfo from "../../../../services/getReportInfo";
import getReportData from "../../../../services/getReportData";
import PrintReport from "./browser/PrintReport";
const TopBarOnBrowser = ({ tabID }) => {
  const dispatch = useDispatch();
  const handleReload = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const reportInfo = await serviceGetReportInfo(tabID);
    if (reportInfo.success) {
      dispatch(setReportInfo({ value: reportInfo, tabID }));
      if (reportInfo.npages > 0) {
        const reportData = await getReportData({ reqID: reportInfo.reqID, pagenum: 1 });
        reportData.pageNum = 1;
        dispatch(setReportData({ value: reportData, tabID }));
      } else {
        console.log("no data");
      }
    } else {
      dispatch(setReportInfo({ value: reportInfo, tabID }));
    }
    dispatch(setLoading({ show: false, tabID }));
  };

  const handleGoToDialog = () => {
    dispatch(setScreenState({ value: "dialog", tabID }));
  };

  return (
    <Container fluid className="object-top-bar">
      <div
        onClick={handleReload}
        className="d-flex align-items-center justify-content-start"
        style={{ cursor: "pointer" }}
      >
        <HiSearch size="1.1rem" className="me-1" />
        Λίστα
      </div>
      <div
        onClick={handleGoToDialog}
        className="d-flex align-items-center justify-content-start"
        style={{ cursor: "pointer" }}
      >
        <FaFilter size="0.9rem" />
      </div>
      <PrintReport tabID={tabID} />
    </Container>
  );
};

export default TopBarOnBrowser;
