import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { setLoading, setPasswordModal } from "../../../redux/features/modals/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import CrmInput from "../CrmInput";
import changePassword from "../../../services/changePassword";
import { setNewPassword } from "../../../redux/features/login/loginSlice";

const ModalChangePassWord = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.modals.password);
  const login = useSelector((state) => state.login);
  const [currentPass, setCurrentPass] = React.useState("");
  const [newPass, setNewPass] = React.useState("");
  const [confirmPass, setConfirmPass] = React.useState("");

  const [currPassError, setCurrPassError] = React.useState(false);
  const [newPassError, seNewPassError] = React.useState(false);
  const [confirmPassError, setConfirmPassError] = React.useState(false);

  const [generalError, setGeneralError] = React.useState({ show: false, message: "" });
  const inputs = [
    {
      label: "Ισχύων κωδικός",
      required: true,
      name: "old-password",
      type: "password",
      readOnly: false,
      value: currentPass,
      function: setCurrentPass,
      condition: currPassError,
      autoComplete: "current-password",
    },
    {
      label: "Νέος κωδικός",
      required: true,
      name: "new-password",
      type: "password",
      readOnly: false,
      value: newPass,
      function: setNewPass,
      condition: newPassError,
      autoComplete: "new-password",
    },
    {
      label: "Επιβεβαίωση",
      required: true,
      name: "new-password",
      type: "password",
      readOnly: false,
      value: confirmPass,
      function: setConfirmPass,
      condition: confirmPassError,
      autoComplete: "new-password",
    },
  ];

  React.useEffect(() => {
    if (!show) {
      setCurrentPass("");
      setNewPass("");
      setConfirmPass("");
      setCurrPassError(false);
      seNewPassError(false);
      setConfirmPassError(false);
    }
  }, [show]);

  const handleAcept = async (e) => {
    setGeneralError({ show: false, message: "" });
    setCurrPassError(false);
    seNewPassError(false);
    setConfirmPassError(false);
    e.preventDefault();
    if (currentPass != login.password) {
      setCurrPassError(true);
      return;
    }
    if (newPass != confirmPass) {
      seNewPassError(true);
      setConfirmPassError(true);
      return;
    }
    dispatch(setLoading(true));
    const response = await changePassword({
      oldPass: currentPass,
      newPass: newPass,
    });
    if (response.success) {
      dispatch(setNewPassword({ ...login, password: newPass }));
      dispatch(setPasswordModal(false));
    } else {
      setGeneralError({ show: true, message: response.error });
    }
    dispatch(setLoading(false));
  };
  return (
    <Modal show={show} animation={false} size="md" onHide={() => dispatch(setPasswordModal(false))} centered>
      <Modal.Header className="p-2 ps-3" closeButton style={{ userSelect: "none" }}>
        Αλλαγή Κωδικού
      </Modal.Header>
      <form onSubmit={handleAcept}>
        <Modal.Body className="d-flex flex-column" style={{ userSelect: "none" }}>
          {inputs.map((item, index) => (
            <CrmInput {...item} key={index} />
          ))}
          {generalError.show && (
            <Alert variant="danger" className="m-0 mt-2">
              {generalError.message}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Button type="submit">Επιβεβαίωση</Button>
          <Button onClick={() => dispatch(setPasswordModal(false))}>Ακύρωση</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ModalChangePassWord;
