import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getFilters, getObjectState, getRecData } from "../../../redux/selectors";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";

const S1CheckBox = ({ element, tabID, screenState, grid }) => {
  const [value, setValue] = React.useState(false);

  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));
  const data = useSelector((state) => getRecData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const currentGridLine = grid?.currentLine;

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleChangeField = (e) => {
    if (screenState == "dialog") {
      setValue(e.target.checked);
      dispatch(setFilter({ tabID, table, key, value: e.target.checked ? 1 : 0 }));
    } else if (screenState == "form") {
      if (grid) {
        dispatch(
          setGridChange({
            tabID,
            name: grid.model,
            key,
            value: e.target.checked ? 1 : 0,
          })
        );
      } else {
        if (objectState == "view") {
          dispatch(changeToBuffer({ tabID }));
          dispatch(setObjectState({ value: "edit", tabID }));
        }
        dispatch(setChange({ tabID, table, key, value: e.target.checked ? 1 : 0 }));
      }
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value == 1);
    } else if (screenState == "form") {
      setValue(data?.[table]?.[0]?.[key] == 1);
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(filters?.data?.[table]?.[key] == 1);
    }
  }, [filters?.data?.[table]?.[key]]);

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      setValue(data?.[table]?.[0]?.[key] == 1);
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if ((currentGridLine?.line?.[key] || currentGridLine?.line?.[key] == "") && grid) {
      setValue(currentGridLine?.line?.[key] == 1);
    }
  }, [currentGridLine?.line?.[key]]);

  return (
    <div className="s1checkbox w-100" style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}>
      <div className="inputGroup">
        {element.caption != "" && (
          <label className="text-truncate" htmlFor={element.index}>
            {`${element.caption}:`}
          </label>
        )}
        <input
          type="checkbox"
          className="ms-1"
          name={element.name}
          disabled={element.readOnly || (screenState == "form" && commandParams.readonly)}
          readOnly={element.readOnly || (screenState == "form" && commandParams.readonly)}
          checked={value}
          onChange={handleChangeField}
          style={{ height: "30px", width: "30px", minHeight: "30px", minWidth: "30px" }}
        />
      </div>
    </div>
  );
};

export default S1CheckBox;
