import React from "react";
import { BsChevronDown } from "react-icons/bs";
import printTemplates from "../../../services/printTemplates";
import PickerSpinner from "../PickerSpinner";
import { useSelector } from "react-redux";
import { getCommand, getCommandByIndex } from "../../../redux/selectors";
import sqlData from "../../../services/sqlData";

const CrmDropDownTemplates = (props) => {
  const boundRef = React.useRef(null);
  const tableRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const [openPicker, setOpenPicker] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  const [data, setData] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  const command = useSelector((state) => getCommandByIndex(state, props.tabID));

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const cliii = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!cliii) {
          setOpenPicker(false);
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handleClick = async () => {
    if (openPicker) {
      setOpenPicker(false);
      setIsFocused(false);
    } else {
      setOpenPicker(true);
      setIsFocused(true);
      if (!data) {
        setLoading(true);
        if (command.split("&")[0] == "SOEXPENDITURES") {
          const response = await sqlData({ SqlName: "printTemplates", sosource: 202111 });
          console.log(response);
          if (response.success && response.totalcount > 0) {
            response.data = response.rows.map((item) => [item.TEMPLATES, item.NAME]);
            setData(response);
          } else {
            setData(response);
          }
        } else {
          const response = await printTemplates(props.tabID);
          setData(response);
        }
        setLoading(false);
      }
    }
  };

  return (
    <div className="s1stringmulti w-100" style={{ flex: `100%`, overflow: "hidden" }}>
      <div className="inputGroup">
        <label className="text-truncate" htmlFor={props.name}>
          {props.label}:
        </label>
        <div
          ref={boundRef}
          className={`input-with-buttons${isFocused ? " underline-focus" : ""}`}
          style={{ display: "flex", flexGrow: 1, gap: "0.5rem" }}
          role="button"
        >
          <div className="d-flex w-100">
            <input
              role="button"
              className="w-100"
              style={{ backgroundColor: "white" }}
              value={props.value}
              readOnly={true}
              onClick={handleClick}
            />
          </div>
          <div role="button" onClick={handleClick}>
            <BsChevronDown color="rgb(64, 180, 252)" size="30px" />
          </div>
        </div>
      </div>
      {openPicker && (
        <div
          className="input-picker-div custom"
          ref={pickerRef}
          style={{
            left: boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 200 > window.innerHeight
                ? 200 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "200px",
            overflow: "hidden",
          }}
        >
          <div
            className="input-picker bg-light w-100 h-100"
            style={{
              overflow: "auto",
            }}
          >
            {/* <PickerSpinner /> */}
            {loading && <PickerSpinner />}
            {data?.success ? (
              <table className="input-picker-table w-100" ref={tableRef}>
                <tbody>
                  {data.data.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={`input-picker-table-row`}
                        onClick={() => {
                          setOpenPicker(false);
                          setIsFocused(false);
                          props.function(item);
                        }}
                      >
                        <td className="py-1 px-2" style={{ height: "40px" }}>
                          {item[1]}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="d-flex h-100 justify-content-center align-items-center">{data?.error}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CrmDropDownTemplates;
