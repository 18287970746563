import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedRow, getTableData } from "../../../../../redux/selectors";
import { setSelectedRow } from "../../../../../redux/features/ui/uiSlice";

const NavigationArrow = ({ tabID }) => {
  const dispatch = useDispatch();
  const selectedRow = useSelector((state) => getSelectedRow(state, tabID));
  const tableData = useSelector((state) => getTableData(state, tabID));

  return (
    <React.Fragment>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => selectedRow > 0 && dispatch(setSelectedRow({ tabID, value: selectedRow - 1 }))}
        style={{
          userSelect: "none",
          cursor: selectedRow > 0 ? "pointer" : "default",
          opacity: selectedRow > 0 ? 1 : 0.5,
        }}
      >
        <IoIosArrowBack size="1rem" />
      </div>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() =>
          tableData && selectedRow < tableData.length - 1 && dispatch(setSelectedRow({ tabID, value: selectedRow + 1 }))
        }
        style={{
          userSelect: "none",
          cursor: tableData && selectedRow < tableData.length - 1 ? "pointer" : "default",
          opacity: tableData && selectedRow < tableData.length - 1 ? 1 : 0.5,
        }}
      >
        <IoIosArrowForward size="1rem" />
      </div>
    </React.Fragment>
  );
};

export default NavigationArrow;
