import React from "react";
import { FaChevronCircleRight } from "react-icons/fa";

const ScrollRight = () => {
  const handleScroll = () => {
    const tabWrapper = document.getElementById("tab-list-wrapper");
    tabWrapper.scrollBy({
      left: 100,
      behavior: "smooth",
    });
  };
  return (
    <button className="tab-list-control-right" onClick={handleScroll}>
      <FaChevronCircleRight />
    </button>
  );
};

export default ScrollRight;
