import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/features/modals/modalsSlice";
import axios from "axios";
import { clear as clearSession } from "../../../redux/features/session/sessionSlice";

const ModalBlocked = () => {
  const dispatch = useDispatch();
  const [isBlocked, setIsBlocked] = React.useState(false);
  const [blcdateDD, setBlcDate] = React.useState();

  const session = useSelector((state) => state.session);

  React.useEffect(() => {
    const today = new Date(`${formatUSDate(new Date())}`);
    const blckdate = new Date(session.blcdate);
    setBlcDate(formatDate(blckdate));
    setIsBlocked(blckdate < today);
  }, []);

  const logout = async () => {
    setIsBlocked(false);
    try {
      dispatch(setLoading(true));
      await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/logout`, {
        key: session.loginid,
      });
      dispatch(setLoading(false));
      dispatch(clearSession());
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error.message);
    }
  };

  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={isBlocked}
      animation={false}
      centered
      backdrop="static"
      keyboard={false}
      size="md"
    >
      <Modal.Body className="d-flex justify-content-start align-items-center" style={{ userSelect: "none" }}>
        <div>
          Η άδεια χρήσης του λογισμικού σας έληξε.
          <br />
          Kαλέστε στο: <a href="tel:2165005060">216 5005060</a> για ανανέωση.
          <br />
          Λήξη: {blcdateDD}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0 d-flex align-items-center justify-content-center">
        <Button variant="primary" onClick={logout}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBlocked;

const formatDate = (date) => {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("/");
};

const formatUSDate = (date) => {
  return [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()), date.getFullYear()].join("/");
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};
